import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab';
import { InputField } from '../Common/BSField';

export default function PasswordChangeForm({ onSubmit, isLoading, response }) {
    const validate = Yup.object({
        old_password: Yup.string()
            .min(6, 'Password must be at least 6 characters.')
            .required('Current password is required.'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters.')
            .required('New password is required.'),
        password2: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Password must match.')
            .required('Confirm password is required.'),
    })
    return (
        <Formik
            initialValues={{
                old_password: '',
                password: '',
                password2: '',
            }}
            validationSchema={validate}
            onSubmit={onSubmit}
        >
            {formik => (
                <>
                    <Form>
                        <InputField label='Current Password' type='password' name='old_password' autoComplete='off' />
                        <InputField label='New Password' type='password' name='password' autoComplete='off'/>
                        <InputField label='Confirm Password' type='password' name='password2' autoComplete='off'/>

                        <LoadingButton
                            loading={isLoading}
                            variant="outlined"
                            type="submit"
                            sx={{mt:3}}
                        >
                            Change Password
                        </LoadingButton>
                        {response &&
                            <div className={`alert mt-3 ${response.success ? 'alert-success' : 'alert-danger'}`} role="alert">  {response.detail}
                            </div>
                        }
                    </Form>
                </>
            )}
        </Formik>
    )
}
