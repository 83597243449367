import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function FormTitleDescSkeleton() {
  return (
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: '3rem' }} animation="wave"/>
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave"/>
      
    </Stack>
  );
}