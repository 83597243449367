import React from 'react'
import TextField from '../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Divider, Stack } from '@mui/material';
import Select from 'react-select'
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Typography } from '@mui/material';

export default function GroupLogicForm({ isLoading, onSubmit }) {
    const [showHelper, setHelper] = React.useState(false)
    // console.log(couponCategoryData.map(stripCouponsCategory))
    const validate = Yup.object({
        target_type: Yup.string()
            .required('Target type is required.'),
        code: Yup.string()
            .required('Code is required.'),
        method_type: Yup.string()
            .required('Method type is required.'),
    })
    return (
        <Formik
            initialValues={{
                group: '',
                target_type: 'region',
                code: 0,
                method_type: 'allow',
            }}
            validationSchema={validate}
            onSubmit={onSubmit}
        >
            {formik => (
                <Form>
                    <TextField label='Code' variant='outlined' type="number" name='code' placeholder='Code' sx={{ mb: 3 }} />
                    <Typography variant="h9" color="#949494">
                        Select Location Group
                    </Typography>
                    <Select
                        options={[
                            { value: 'region', label: 'Particular Region' },
                            { value: 'circle', label: 'Particular Circle' },
                            { value: 'custom', label: 'Custom PIN' },
                        ]}
                        // onChange={(e) => console.log(e.value)}
                        onChange={(e) => formik.setFieldValue('target_type', e.value)}
                    />

                    <Stack sx={{ mt: 3, mb: 3 }} spacing={1}>
                        <Typography variant="h9" color="#949494">
                            Method Type
                        </Typography>
                        <Select
                            options={[
                                { value: 'allow', label: 'Allow Access' },
                                { value: 'block', label: 'Block Access' },
                            ]}
                            // onChange={(e) => console.log(e.value)}
                            onChange={(e) => formik.setFieldValue('method_type', e.value)}
                        />
                    </Stack>
                    {formik.values.target_type !== 'custom' && <>
                        <Divider />
                        <Box textAlign='center' sx={{ mt: 1, mb: 3 }}>
                            <Button variant="text" onClick={() => setHelper(!showHelper)}> {showHelper ? 'Hide Helper' : 'Show Helper'}</Button>
                            <Stack sx={{ display: showHelper ? 'block' : 'none' }}>
                                {formik.values.target_type === 'region' && <>
                                    <Typography variant="h9" color="#949494">
                                        Regions
                                    </Typography>
                                    <Select
                                        options={[
                                            { value: 1, label: '1 - Northern region' },
                                            { value: 2, label: '2 - Northern region' },
                                            { value: 3, label: '3 - Western region' },
                                            { value: 4, label: '4 - Western region' },
                                            { value: 5, label: '5 - Southern region' },
                                            { value: 6, label: '6 - Eastern region' },
                                            { value: 9, label: '9 - Army postal service' }
                                        ]}
                                        // onChange={(e) => console.log(e.value)}
                                        onChange={(e) => formik.setFieldValue('code', e.value)}
                                    />
                                </>}
                                {formik.values.target_type === 'circle' && <>
                                    <Typography variant="h9" color="#949494">
                                        Circles
                                    </Typography>
                                    <Select
                                        options={[
                                            { value: 11, label: '11 - Delhi' },
                                            { value: 12, label: '12 - Haryana' },
                                            { value: 13, label: '13 - Haryana' },
                                            { value: 14, label: '14 - Punjab' },
                                            { value: 15, label: '15 - Punjab' },
                                            { value: 16, label: '16 - Punjab' },
                                            { value: 17, label: '17 - Himachal Pradesh' },
                                            { value: 20, label: '20 - Uttar Pradesh and Uttaranchal' },
                                            { value: 21, label: '21 - Uttar Pradesh and Uttaranchal' },
                                            { value: 22, label: '22 - Uttar Pradesh and Uttaranchal' },
                                            { value: 23, label: '23 - Uttar Pradesh and Uttaranchal' },
                                            { value: 24, label: '24 - Uttar Pradesh and Uttaranchal' },
                                            { value: 25, label: '25 - Uttar Pradesh and Uttaranchal' },
                                            { value: 26, label: '26 - Uttar Pradesh and Uttaranchal' },
                                            { value: 70, label: '70 - West Bengal' },
                                            { value: 71, label: '71 - West Bengal' },
                                            { value: 72, label: '72 - West Bengal' },
                                            { value: 73, label: '73 - West Bengal' },
                                            { value: 74, label: '74 - West Bengal' },
                                        ]}
                                        // onChange={(e) => console.log(e.value)}
                                        onChange={(e) => formik.setFieldValue('code', e.value)}
                                    />
                                </>}
                            </Stack>
                        </Box>
                    </>
                    }

                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        type="submit"
                        disableElevation
                    >
                        Create
                    </LoadingButton>

                    {/* <Stack sx={{ width: '100%', mt: 3 }}>
                    {response.status === 401 && <Alert severity="error">{response.detail} </Alert>}
                    {response.status === 200 && <Alert severity="success"> Login Success!</Alert>}
                </Stack> */}
                </Form>
            )}

        </Formik>
    )
}
