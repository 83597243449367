import { Container, Grid, Stack } from '@mui/material';
import React from 'react';
import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
import DataTableBase from '../../components/Common/DataTableBase';
import AddCouponCategoryModal from '../../components/Modal/AddCouponCategoryModal';
import { useCouponsCategoryList } from '../../hooks/coupon';


const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
export default function CouponCategoryPage() {
    const { couponCategoryData, couponCategoryIsLoading } = useCouponsCategoryList();

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
            width: '60px'
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status ? 'Active' : 'Deactive',
            sortable: true,
            width: '100px'
        }
    ];
    return (
        <Container maxWidth="xxl">
            <PageBreadcrumbs
                title="Coupon Categories"
                title2="Categories"
                path={[{ name: 'Coupons', url: '/coupon/list' }]}
            />
            <Stack direction="row">
                <button className="btn btn-primary shadow-none" data-bs-toggle="modal" data-bs-target="#addCouponCategory">Add new</button>
                <AddCouponCategoryModal />
            </Stack>

            <Grid item xs={12}>

                <DataTableBase
                    columns={columns}
                    data={couponCategoryData}
                    direction="auto"
                    fixedHeaderScrollHeight="300px"
                    pagination
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    expandableRows
                    subHeader
                    striped
                    expandableRowsComponent={ExpandedComponent}
                    progressPending={couponCategoryIsLoading}
                    highlightOnHover
                    pointerOnHover
                />

            </Grid>
        </Container>
    )
}
