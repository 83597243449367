import React from 'react'
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { StyledMenu } from '../Styled/StyledMenu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useFormTweakChange } from '../../hooks/form';
import { toast } from 'react-toastify';


export default function MainCard({ data, handleDeleteForm }) {
    let navigate = useNavigate();
    const { handleUpdateFavStatus, updateFavIsLoading } = useFormTweakChange();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const { form_id, title, description, created_date, is_fav, updated_at } = data;

    // Handle favourite add/remove
    const handleFavorite = async (form_id) => {
        const res = await handleUpdateFavStatus(form_id, !is_fav)
        if (res) {
            !is_fav ? toast.success('Added to favourite.') : toast.info('Removed from favourite.')
        } else {
            toast.error('Try again.')
        }
    }
    return (
        <Card sx={{ maxWidth: 345, marginRight: 2, marginBottom: 2 }} >
            <CardHeader
                action={
                    <IconButton
                        id="survey-menu-button"
                        aria-label="settings"
                        aria-controls={open ? 'survey-menus' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                }
                title={title}
                subheader={created_date}
            // onClick={() => navigate(`/preview/${form_id}`)}
            // style={{cursor: 'pointer'}} 
            />
            <StyledMenu
                id="survey-menus"
                MenuListProps={{
                    'aria-labelledby': 'survey-menu-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => navigate(`/preview/${form_id}`)} disableRipple>
                    <VisibilityIcon />
                    Preview
                </MenuItem>
                <MenuItem onClick={() => navigate(`/form/${form_id}`)} disableRipple>
                    <EditIcon />
                    Edit
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => { handleDeleteForm(form_id, title); handleClose(); }} disableRipple>
                    <DeleteIcon />
                    Delete
                </MenuItem>
            </StyledMenu>

            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {description}
                    {/* <br/> */}
                    Modified - {moment(updated_at).startOf('hour').fromNow()}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton onClick={() => handleFavorite(form_id)} disabled={updateFavIsLoading}>
                    {is_fav ? <FavoriteIcon style={{ color: '#dc3545' }} /> : <FavoriteBorderIcon />}
                </IconButton>
                <IconButton aria-label="share">
                    <ShareIcon />
                </IconButton>
            </CardActions>
        </Card>
    )
}
