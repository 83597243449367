import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export default function MenuItem({primary, to, icon}) {
	const location = useLocation();
	return (
		<ListItem disablePadding>
			<ListItemButton component={NavLink} to={to} selected={to === location.pathname}>
				<ListItemIcon>
					{icon}
				</ListItemIcon>
				<ListItemText primary={primary} />
			</ListItemButton>
		</ListItem>
	)
}
