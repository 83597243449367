import { API_HOST_URL } from "../../config"
import { endpoints } from "../../config/endpoints"
import { getTokens } from "../../utils/shortcuts";
import { fetchJson } from "../api"


export const getFormDetail = async (form_id) => {
    const { accessToken } = getTokens();
    return await fetchJson(`${API_HOST_URL}/${endpoints.survey.forms_list}/${form_id}`, {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    })
}