import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function FormSkeleton() {
  return (
    <Stack spacing={1} >
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" width={`99%`} sx={{ fontSize: '2rem' }} animation="wave"/>
      {/* <Skeleton variant="rectangular" width={210} height={60} /> */}
      <Skeleton variant="rounded" width={`99%`} height={100} />
    </Stack>
  );
}