import React from 'react'
import { Outlet } from 'react-router-dom'
import { useUser } from '../../hooks/user';
import Header from '../Header'
import PasswordChangeModal from '../Modal/PasswordChangeModal';

export default function Layout() {
    const { user, isLoading } = useUser();
    return (
        <>
            {user && !isLoading &&
                <Header
                    user={user}
                    isLoading={isLoading}
                />
            }
            <PasswordChangeModal
                user={user}
            />
            <Outlet />
        </>
    )
}
