import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../components/Layout';
import LoginPage from '../pages/account/login';
import UsersManagement from '../pages/user/list';
import FormPage from '../pages/form/form';
import HomePage from '../pages/home';
import ProtectedRoutes from './ProtectedRoutes';
import CouponListPage from '../pages/coupon/list';
import UserDetailPage from '../pages/user/detail';
import LocationGroupsPage from '../pages/location/groups';
import LocationGroupLogicPage from '../pages/location/logic';
import CouponCategoryPage from '../pages/coupon/category';
import FormPreviewPage from '../pages/form/preview';
import FavouriteFormsPage from '../pages/form/favourite';


export default function AppRoutes() {
    return (
        <Routes>
            <Route path='/' element={<ProtectedRoutes />}>
                <Route index element={<HomePage />} />
                <Route path='/form/favourites' element={<FavouriteFormsPage />} />
                <Route path='/form/:id' element={<FormPage />} />
                <Route path='/preview/:id' element={<FormPreviewPage />} />


                <Route path='/user/list' element={<UsersManagement />} />
                <Route path='/user/detail/:username' element={<UserDetailPage />} />
                <Route path='/coupon/list' element={<CouponListPage />} />
                <Route path='/coupon/category/list' element={<CouponCategoryPage />} />
                <Route path='/location/group/list' element={<LocationGroupsPage />} />
                <Route path='/location/group/logic/:id' element={<LocationGroupLogicPage />} />
            </Route>

            <Route element={<Layout />}>
                <Route path='account/login' element={<LoginPage />} />
            </Route>
            <Route path='*' element={<h1>404 Page not found</h1>} />
        </Routes>
    )
}
