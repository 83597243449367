import { API_HOST_URL } from "../config";
import { endpoints } from "../config/endpoints";
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { fetchJson } from "../libs/api";
import { getTokens } from "../utils/shortcuts";


const LOCATIONS_QUERY_KEY = 'locations'
const LOCATION_QUERY_KEY = 'location'
const LOCATION_LOGIC_QUERY_KEY = 'group_logic'

export function useLocationGroups() {
    const { isLoading, data } = useQuery([LOCATIONS_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const users = await fetchJson(`${API_HOST_URL}/${endpoints.location.groups}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return users;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000, // ms
    })
    return { locationGroupsData: data, locationGroupsIsLoading: isLoading }
}


export const stripLocationGroups = (data) => {
    return {
        value: data.id,
        label: data.name
    }
}

export function useCreateLocationGroup() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.location.groups}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(values)
    }, true))
    return {
        handleCreateGroup: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (res.status === 201) {
                    await queryClient.invalidateQueries([LOCATIONS_QUERY_KEY])
                    return {
                        success: true,
                        detail: 'Group created.',
                        ...data
                    }
                } else {
                    return {
                        success: false,
                        detail: data.name[0]
                    }
                }
            } catch (err) {
                return {
                    success: false,
                    detail: err
                }
            }
        },
        createGroupIsLoading: mutation.isLoading,
    }
}


// Get location group Detail
export function useGetLocGroupDetail(id) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [LOCATION_QUERY_KEY], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.location.groups}/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                // console.log('hook data ->', data)
                const data = await res.json()
                return data
            } catch (err) {
                return undefined;
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        // enabled: !!questions,
        retry: 3,
        refetchOnMount: true, // ms
    })
    return { locGroupData: data, locGroupIsLoading: isLoading, isError, status }
}


// Delete Location Group 
export function useDeleteLocationGroup() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
	const mutation = useMutation(({ id }) => fetchJson(`${API_HOST_URL}/${endpoints.location.groups}/${id}`, {
		method: 'DELETE',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        }
	}, true))
    return { 
        handleDeleteGroup: async (id) => {
            try {
                const res = await mutation.mutateAsync({ id });
                await queryClient.invalidateQueries([LOCATIONS_QUERY_KEY])              
                return {
                    success: true,
                    detail: res.status
                };
                
            } catch (err) {
                return {
                    success: false,
                    detail: err
                }
            }            
        },
        isGroupDeleting: mutation.isLoading,
    }
}

//  Get location group logic detail
export function useGetLocGroupLogicDetail(group_id, locGroupData) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [LOCATION_LOGIC_QUERY_KEY, locGroupData], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.location.groups_logic}?group=${group_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                // console.log('hook data ->', data)
                const data = await res.json()
                return data
            } catch (err) {
                return undefined;
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        enabled: !!locGroupData,
        retry: 3,
        refetchOnMount: true, // ms
    })
    return { locGroupLogicData: data, locGroupLogicIsLoading: isLoading, isError, status }
}

// location group logic actions
export function useLocationGroupLogic() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values, method = 'POST') => fetchJson(`${API_HOST_URL}/${endpoints.location.groups_logic}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(values)
    }, true))
    return {
        handleGroupLogic: async (values, method) => {
            try {
                const res = await mutation.mutateAsync(values, method);
                const data = await res.json();
                if (method === 'POST') {
                    if (res.status === 201) {
                        await queryClient.invalidateQueries([LOCATION_LOGIC_QUERY_KEY])
                        return {
                            success: true,
                            detail: 'Logic created.',
                            ...data
                        }
                    } else {
                        return {
                            success: false,
                            detail: 'data.name[0]'
                        }
                    }
                } else {
                    if (res.status === 200) {
                        await queryClient.invalidateQueries([LOCATION_LOGIC_QUERY_KEY])
                        return {
                            success: true,
                            detail: 'Logic updated.',
                            ...data
                        }
                    } else {
                        return {
                            success: false,
                            detail: 'data.name[0]'
                        }
                    }
                }

            } catch (err) {
                return {
                    success: false,
                    detail: err
                }
            }
        },
        groupLogicIsLoading: mutation.isLoading,
    }
}

// Delete Group Logic
export function useDeleteGroupLogic() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
	const mutation = useMutation(({ id }) => fetchJson(`${API_HOST_URL}/${endpoints.location.groups_logic}/${id}`, {
		method: 'DELETE',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        }
	}, true))
    return { 
        handleDeleteLogic: async (id) => {
            try {
                const res = await mutation.mutateAsync({ id });
                await queryClient.invalidateQueries([LOCATION_LOGIC_QUERY_KEY])              
                return {
                    success: true,
                    detail: res.status
                };
                
            } catch (err) {
                return {
                    success: false,
                    detail: err
                }
            }            
        },
        isLogicDeleting: mutation.isLoading,
    }
}