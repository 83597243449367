import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
// import moment from 'moment';
import { API_HOST_URL } from '../config';
import { endpoints } from '../config/endpoints';
import { fetchJson } from '../libs/api';
import { getTokens } from '../utils/shortcuts';


const FORMS_QUERY_KEY = 'forms'
// const FORM_QUERY_KEY = 'form'

// Fetch All Forms from the server
export function useFormsList(param='') {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, refetch } = useQuery(
        [FORMS_QUERY_KEY], async () => {
            try {
                return await fetchJson(`${API_HOST_URL}/${endpoints.survey.forms_list}?${param}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
            } catch (err) {
                return undefined;
            }
        }, {
        // enabled: false,
        cacheTime: 0,
        staleTime: 1, // ms 3600 Sec
        refetchOnMount: true,
    })
    return { formData: data, formIsLoading: isLoading, formIsError: isError, refetch }
}


// // Fetch Single Form from the server
export function useGetFormDetail(form_id) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        ['form'], async () => {
            try {
                const data = await fetchJson(`${API_HOST_URL}/${endpoints.survey.forms_list}/${form_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                // console.log('hook data ->', data)
                return data
            } catch (err) {
                return undefined;
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        // enabled: !!questions,
        retry: 3,
        refetchOnMount: true, // ms
    })
    return { data, isLoading, isError, status }
}


// Post Form data to the server
export function useCreateForm() {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation(({ title, description, form_id, form_data }) => fetchJson(`${API_HOST_URL}/${endpoints.survey.forms_list}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ title, description, form_id, form_data })
    }), {
        retry: 1,
    })
    return {
        handleCreateForm: async (title, description, form_id, form_data) => {
            try {
                await mutation.mutateAsync({ title, description, form_id, form_data });
                await queryClient.invalidateQueries([FORMS_QUERY_KEY])
                return true;
            } catch (err) {
                return false
            }
        },
        createFormIsError: mutation.isError,
        createFormIsLoading: mutation.isLoading,
        createFormIsSuccess: mutation.isSuccess,
        createFormError: mutation.error,
    }
}

// Update Form data to the server
export function useUpdateForm() {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation(({ form_id, title, description, form_data }) => fetchJson(`${API_HOST_URL}/${endpoints.survey.forms_list}/${form_id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ title, description, form_data })
    }), {
        retry: 3,
    })
    return {
        handleUpdateForm: async (form_id, title, description, form_data) => {
            try {
                await mutation.mutateAsync({ form_id, title, description, form_data });
                await queryClient.invalidateQueries([FORMS_QUERY_KEY])
                return true;
            } catch (err) {
                return false
            }
        },
        updateFormIsError: mutation.isError,
        updateFormIsLoading: mutation.isLoading,
        updateFormIsSuccess: mutation.isSuccess,
    }
}


// Update Form Settings data to the server
export function useUpdateFormSetting() {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();

    const mutation = useMutation(({ form_id, point, silver_point, gold_point, platinum_point, time_limit, editable, publish_date, expiry_date, location_group, status }) => fetchJson(`${API_HOST_URL}/${endpoints.survey.forms_list}/${form_id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
            point,
            silver_point,
            gold_point,
            platinum_point,
            time_limit: time_limit >= 1 ? time_limit : null,
            editable,
            publish_date: publish_date ? dayjs(publish_date).format('YYYY-MM-DD') : null,
            expiry_date: expiry_date ? dayjs(expiry_date).format('YYYY-MM-DD') : null,
            location_group,
            status
        })
    }), {
        retry: 3,
    });

    return {
        handleUpdateFormSetting: async (form_id, point, silver_point, gold_point, platinum_point, time_limit, editable, publish_date, expiry_date, location_group, status) => {
            try {
                await mutation.mutateAsync({
                    form_id,
                    point,
                    silver_point,
                    gold_point,
                    platinum_point,
                    time_limit,
                    editable,
                    publish_date,
                    expiry_date,
                    location_group,
                    status
                });
                // console.log('got date ', expiry_date);
                // console.log('got date ', moment(expiry_date));
                // console.log('got date ', moment(expiry_date).format('YYYY-MM-DD'));
                await queryClient.invalidateQueries([FORMS_QUERY_KEY])
                return true;
            } catch (err) {
                return false
            }
        },
        updateFormSettingIsError: mutation.isError,
        updateFormSettingIsLoading: mutation.isLoading,
        updateFormSettingIsSuccess: mutation.isSuccess,
    }
}


// Change Form favourite Status
export function useFormTweakChange() {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();

    const mutation = useMutation(({ form_id, is_fav }) => fetchJson(`${API_HOST_URL}/${endpoints.survey.forms_list}/${form_id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ is_fav })
    }), {
        retry: 3,
    });

    return {
        handleUpdateFavStatus: async (form_id, is_fav) => {
            try {
                await mutation.mutateAsync({ form_id, is_fav });
                await queryClient.invalidateQueries([FORMS_QUERY_KEY])
                return true;
            } catch (err) {
                return false
            }
        },
        updateFavIsLoading: mutation.isLoading,
    }
}

// Delete Form data to the server
export function useDeleteForm() {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation(({ form_id }) => fetchJson(`${API_HOST_URL}/${endpoints.survey.forms_list}/${form_id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }, true), {
        retry: 3,
    })
    return {
        handleDeleteForm: async (form_id) => {
            try {
                const res = await mutation.mutateAsync({ form_id });
                await queryClient.invalidateQueries([FORMS_QUERY_KEY])
                // const data = await res.json()
                // console.log(data)
                return {
                    status: res.status
                };
            } catch (err) {
                return {
                    status: 403
                };
            }
        },
        deleteFormIsError: mutation.isError,
        deleteFormIsLoading: mutation.isLoading,
        deleteFormIsSuccess: mutation.isSuccess,
    }
}