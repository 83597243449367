import { capitalize, Card, CardContent, Grid } from '@mui/material'
import moment from 'moment'
import React from 'react'

export default function ProfileInformation({ user }) {
    return (
        <>
            <div className="d-flex justify-content-between">
                <div>
                    <h1 className="display-6">{user.account_name}</h1>
                    <p className="lead"><em>{user.user_type} User</em></p>
                </div>
                <div>
                    <h3 className="display-6">Balance: {user.stroke_balance}</h3>
                    <p className="lead">{user.is_unlocked ? 'Ready for redemption' : 'Not ready for redemption'}</p>
                </div>
            </div>
            <Grid container>

                <Grid item xs={6} sx={{ p: 1 }}>
                    <Card>
                        <CardContent>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <td colSpan={2}><em>Personal Informations</em></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="col">Gender:</th>
                                        <td>{user.gender !== null ? capitalize(user.gender) : 'Not updated'}</td>
                                    </tr>

                                    <tr>
                                        <th scope="col">Year of Birth:</th>
                                        <td>{user.year_of_birth ?? 'Not updated'}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Registered at:</th>
                                        <td>{moment(user.updated_at).format('DD-MM-YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Last Updated:</th>
                                        <td>{moment(user.updated_at).format('DD-MM-YYYY hh:mm')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} sx={{ p: 1 }}>
                    <Card>
                        <CardContent>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <td colSpan={2}><em>Account Informations</em></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="col">Referral ID:</th>
                                        <td>{user.referral_id}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Username:</th>
                                        <td>{user.username}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Email:</th>
                                        <td>{user.email} (<small><em>{user.is_email_verified ? 'Verified' : 'Not verified'}</em></small>)</td>
                                    </tr>
                                    {/* <tr>
                                        <th scope="col">Mobile:</th>
                                        <td>{user.mobile} ({user.is_mobile_verified ? 'Verified' : 'Not verified'})</td>
                                    </tr> */}
                                    <tr>
                                        <th scope="col">Last Login:</th>
                                        <td><em>{user.last_login ? moment(user.last_login).format('DD-MM-YYYY hh:mm') : 'Not logged yet'}</em></td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
