import React from 'react'
import { useField } from 'formik';
import { TextareaAutosize } from '@mui/material';

export default function TextAreaField({ label, ...props }) {
	const [field ] = useField(props);
	return (
		<TextareaAutosize 
			label={label}
			variant='standard'
			// fullWidth
			{...field}
			{...props}
			// error={meta.touched && Boolean(meta.error)}
			// helperText={meta.touched && meta.error}
            className="description"
		/>
	)
}

