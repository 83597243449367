import { Button, Container, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserDetail, useUsersReferralList } from '../../hooks/user';
import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
import Loader from '../../components/Loader';
import { useStrokeTransactions } from '../../hooks/transactions';
import TransactionsTable from '../../components/User/TransactionsTable';
import { useUserSubmissions } from '../../hooks/submission';
import SubmissionTable from '../../components/User/SubmissionTable';
import ProfileInformation from '../../components/User/ProfileInformation';
import { useCouponRedemptions } from '../../hooks/coupon';
import CouponRedemptionTable from '../../components/User/CouponRedemptionTable';
import UsersReferralTable from '../../components/User/UsersReferralTable';


export default function UserDetailPage() {
    const [showTransactions, setShowTransactions] = useState(false);
    const [showSubmissions, setShowSubmissions] = useState(false);
    const [showRedemptions, setShowRedemptions] = useState(false);
    const [showReferrals, setShowReferrals] = useState(false);

    const { username } = useParams();
    const { userData, userIsLoading } = useGetUserDetail(username);
    const { strokeTransactionData, strokeTransactionIsLoading } = useStrokeTransactions(userData?.user?.id, showTransactions);
    const { submissionsData, submissionsIsLoading } = useUserSubmissions(userData?.user?.id, showSubmissions);
    const { redemptionsData, redemptionsDataIsLoading } = useCouponRedemptions(userData?.user?.id, showRedemptions);
    const { usersReferralData, usersReferralIsLoading } = useUsersReferralList(userData?.user?.id, showReferrals);

    // console.log(usersReferralData)
    if (userIsLoading) {
        return <Loader />
    }

    return (
        <Container maxWidth="xxl">
            {
                userData.success ?
                    <PageBreadcrumbs
                        title="User Information"
                        title2={username}
                        path={[{ name: 'Users', url: '/user/list' }]}
                    /> : <h1>User not found</h1>
            }

            <Grid container>
                <Grid item xs={12} sx={{ p: 1 }}>
                    <ProfileInformation
                        user={userData.user}
                    />

                    <Button size="medium" variant='contained' sx={{ ml: 1 }} onClick={() => setShowSubmissions(!showSubmissions)}>{showSubmissions ? 'Hide' : 'View'} Submissions</Button>
                    <Button size="medium" variant='contained' sx={{ ml: 1 }} onClick={() => setShowTransactions(!showTransactions)}>{showTransactions ? 'Hide' : 'View'} Stroke Transactions</Button>
                    <Button size="medium" variant='contained' sx={{ ml: 1 }} onClick={() => setShowRedemptions(!showRedemptions)}>{showRedemptions ? 'Hide' : 'View'} Coupon Redemptions</Button>
                    <Button size="medium" variant='contained' sx={{ ml: 1 }} onClick={() => setShowReferrals(!showReferrals)}>{showReferrals ? 'Hide' : 'View'} Referrals</Button>
                </Grid>

                <Grid item xs={12} sx={{ p: 2 }} style={{ display: showTransactions ? 'block' : 'none' }}>
                    <TransactionsTable
                        data={strokeTransactionData}
                        isLoading={strokeTransactionIsLoading}
                    />
                </Grid>

                <Grid item xs={12} sx={{ p: 2 }} style={{ display: showSubmissions ? 'block' : 'none' }}>
                    <SubmissionTable
                        data={submissionsData}
                        isLoading={submissionsIsLoading}
                    />
                </Grid>
                <Grid item xs={12} sx={{ p: 2 }} style={{ display: showRedemptions ? 'block' : 'none' }}>
                    <CouponRedemptionTable
                        data={redemptionsData}
                        isLoading={redemptionsDataIsLoading}
                    />
                </Grid>
                <Grid item xs={12} sx={{ p: 2 }} style={{ display: showReferrals ? 'block' : 'none' }}>
                    <UsersReferralTable
                        data={usersReferralData}
                        isLoading={usersReferralIsLoading}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}
