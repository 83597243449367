import { API_HOST_URL } from "../config";
import { endpoints } from "../config/endpoints";
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { fetchJson } from "../libs/api";
import { getTokens } from "../utils/shortcuts";


// const COUPON_QUERY_KEY = 'coupon'
const COUPONS_QUERY_KEY = 'coupons';
const COUPON_QUERY_KEY = 'coupon';
const COUPON_CATEGORY_QUERY_KEY = 'coupon_category';
const COUPON_REDEMPTIONS_QUERY_KEY = 'user_redemptions';
// Add Coupon
// export function useAddCoupon() {
//     // FIXME: DELETE THIS FUNCTION NOT USING ANYWHRE
//     const queryClient = useQueryClient();
//     const { accessToken } = getTokens();
// 	const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.coupons.list}`, {
// 		method: 'POST',
// 		headers: { 
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${accessToken}` 
//         },
// 		body: JSON.stringify(values)
// 	}, true))
//     return { 
//         handleAddNewCoupon: async (values) => {
//             try {
//                 const res = await mutation.mutateAsync(values);
//                 const data = await res.json();
//                 if (res.status === 201) {
//                     console.log(data) 
//                     await queryClient.invalidateQueries([COUPONS_QUERY_KEY])
//                     return {
//                         success: true,
//                         detail: 'Coupon has been added.',
//                         ...data
//                     }
//                 }                
//                 return data

//             } catch (err) {
//                 return {
//                     success: false,
//                     detail: err
//                 }
//             }            
//         },
//         couponAddIsLoading: mutation.isLoading,
//     }
// }

// Delete Coupon
export function useDeleteCoupon() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation(({ slug }) => fetchJson(`${API_HOST_URL}/${endpoints.coupons.list}/${slug}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }, true))
    return {
        handleDeleteCoupon: async (slug) => {
            try {
                const res = await mutation.mutateAsync({ slug });
                await queryClient.invalidateQueries([COUPONS_QUERY_KEY])
                return {
                    success: true,
                    detail: res.status
                };

            } catch (err) {
                return {
                    success: false,
                    detail: err
                }
            }
        },
        isCouponDeleting: mutation.isLoading,
    }
}


// Get Coupon Categories
export function useCouponsCategoryList() {
    const { isLoading, data } = useQuery([COUPON_CATEGORY_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const users = await fetchJson(`${API_HOST_URL}/${endpoints.coupons.category}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return users;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000, // ms
    })
    return { couponCategoryData: data, couponCategoryIsLoading: isLoading }

}

// Coupon Category Create and Update
export function useCouponCategory() {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation((data) => fetchJson(`${API_HOST_URL}/${endpoints.coupons.category}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(data)
    }, true), {
        retry: 2,
    })
    return {
        handleCreateCategory: async (data) => {
            try {
                const res = await mutation.mutateAsync(data);
                await queryClient.invalidateQueries([COUPON_CATEGORY_QUERY_KEY])
                if (res.status === 201) {
                    return {
                        success: true,
                        detail: 'Category added successfully.',
                        data: await res.json()
                    }
                } else {
                    return {
                        success: false,
                        detail: 'Validation error.'
                    }
                }
            } catch (err) {
                return {
                    success: false,
                    detail: err
                }
            }
        },

        isCouponCategoryLoading: mutation.isLoading,
    }
}

// Strip for select dropdown
export const stripCouponsCategory = (data) => {
    return {
        value: data.id,
        label: data.name
    }
}

// Fetch All Coupons
export function useCouponsList() {
    const { isLoading, data, refetch } = useQuery([COUPONS_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const users = await fetchJson(`${API_HOST_URL}/${endpoints.coupons.list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return users;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000, // ms
    })
    return { couponsData: data, couponsIsLoading: isLoading, refetchCoupons: refetch }
}

// Get coupon Detail
export function useCouponDetail(slug, enabled = true) {
    // console.log('sss -?> ', slug)
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status, refetch } = useQuery(
        [COUPON_QUERY_KEY], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.coupons.list}/${slug}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                // console.log('hook data ->', data)
                const data = await res.json()
                return data
            } catch (err) {
                return undefined;
            }
        }, {
        // cacheTime: 0,
        // staleTime: 0,
        enabled: enabled,
        retry: 3,
        // refetchOnMount: true, // ms
        // refetchOnWindowFocus: true, // ms
    })
    return { couponData: data, couponDataIsLoading: isLoading, isError, status, refetch }
}





//  Get User's Coupon Redemptions
export function useCouponRedemptions(user_id, enabled) {
    const { accessToken } = getTokens();

    const { isLoading, isError, data, status } = useQuery(
        [COUPON_REDEMPTIONS_QUERY_KEY, enabled], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.coupons.redemptions}/${user_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                const data = await res.json()
                // console.log('hook data ->', data)
                return data
            } catch (err) {
                return undefined;
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        enabled: !!enabled,
        retry: 3,
        refetchOnMount: true, // ms
    })

    return { redemptionsData: data, redemptionsDataIsLoading: isLoading, isError, status }
}