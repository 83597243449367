import React from 'react';
import './Template.css';
// import { IconButton } from '@mui/material';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ShortcutCard from './ShortcutCard';
import uuid from 'react-uuid'
import { useNavigate } from 'react-router-dom';
import { useCreateForm } from '../../hooks/form';
import { toast } from 'react-toastify';
import { Snackbar } from '@mui/material';

export default function Template() {
    let navigate = useNavigate();
    const { handleCreateForm, createFormIsError, createFormIsLoading } = useCreateForm();
    const [open, setOpen] = React.useState(false);

    const handleCreateNewForm = async () => {
        const toast_process = toast.loading("Please wait...")
        const id = uuid()
        const isCreated = await handleCreateForm(
            'Untitled survey',
            '',
            id,
            {},
        );

        isCreated ? toast.update(toast_process, { render: "Survey form is created.", type: "success", isLoading: false, autoClose: 4000, }) : toast.update(toast_process, { render: "Survey form creation failed.", type: "error", isLoading: false, autoClose: 4000, })
        isCreated && navigate(`/form/${id}`);
    }

    createFormIsError && setOpen(true)
    return (
        <div className="template">
            {/* <div className="template-top">
				<div className="template-left">
					<span style={{ fontSize: '16px', color: '#202124' }}>Start creating survey</span>
				</div>				
			</div> */}
            <div className="template-body">
                <ShortcutCard
                    title="Blank"
                    desc="Create a new survey from."
                    btnName='Create Survey'
                    onClick={handleCreateNewForm}
                    isLoading={createFormIsLoading}
                />
                <ShortcutCard
                    title="Users"
                    desc="Manage all users."
                    btnName='View All Users'
                    onClick={() => navigate('/user/list')}
                    isLoading={false}
                />
                <ShortcutCard
                    title="Coupons"
                    desc="Manage all coupons."
                    btnName='View All Coupons'
                    onClick={() => navigate('/coupon/list')}
                    isLoading={false}
                />
            </div>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                message="Something wents wrong."
            />
        </div>
    )
}
