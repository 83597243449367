import { API_HOST_URL, TOKEN_STORE_NAME } from "../config";
import { endpoints } from "../config/endpoints";
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { fetchJson } from "../libs/api";
import { getTokens } from "../utils/shortcuts";

const USER_QUERY_KEY = 'user'
const USER_DETAIL_QUERY_KEY = 'user_detail'
const USERS_QUERY_KEY = 'users'
const USERS_REFERRALS_QUERY_KEY = 'users_referrals'


// Get User
export function useUser() {
    const { isLoading, error, data, status } = useQuery([USER_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const user = await fetchJson(`${API_HOST_URL}/${endpoints.account.user}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return user.data
        } catch (err) {
            return undefined;
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000, // ms
    })
    return { user: data, isLoading, error, status }
}

// Login 
export function useLogin() {
    const queryClient = useQueryClient();
    const mutation = useMutation(({ username, password }) => fetchJson(`${API_HOST_URL}/${endpoints.account.login}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            username,
            password
        })
    }, true))
    return {
        handleLogin: async (username, password) => {
            try {
                const res = await mutation.mutateAsync({ username, password });
                const user = await res.json();
                if (res.status === 200) {
                    const { tokens } = user;
                    localStorage.setItem(TOKEN_STORE_NAME, JSON.stringify(tokens));
                    queryClient.setQueryData([USER_QUERY_KEY], user);
                }
                return {
                    status: res.status,
                    ...user
                }
            } catch (err) {
                return {
                    status: 400,
                    detail: err
                }
            }
        },
        loginError: mutation.isError,
        loginLoading: mutation.isLoading,
    }
}

// Logout
export function useLogout() {
    const queryClient = useQueryClient();
    const { accessToken, refreshToken } = getTokens();
    const mutation = useMutation(() => fetchJson(`${API_HOST_URL}/${endpoints.account.logout}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ refresh: refreshToken }),
    }, true))
    return async () => {
        try {
            await mutation.mutateAsync();
            localStorage.removeItem(TOKEN_STORE_NAME);
            queryClient.setQueryData([USER_QUERY_KEY], undefined);
            return true
        } catch (err) {
            return false
        }
    }
}


// Get Users List
export function useUsersList() {
    const { isLoading, data } = useQuery([USERS_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const users = await fetchJson(`${API_HOST_URL}/${endpoints.users.list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return users;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000, // ms
    })
    return { usersData: data, usersIsLoading: isLoading }
}

// Get User's Detail
export function useGetUserDetail(username) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [USER_DETAIL_QUERY_KEY], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.users.list}/${username}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                // console.log('hook data ->', data)
                const data = await res.json()
                return data
            } catch (err) {
                return undefined;
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        // enabled: !!questions,
        retry: 3,
        refetchOnMount: true, // ms
    })
    return { userData: data, userIsLoading: isLoading, isError, status }
}


// Password Change
export function usePasswordChange() {
    const { accessToken } = getTokens();
    const mutation = useMutation(({ old_password, password, password2, user_id }) => fetchJson(`${API_HOST_URL}/${endpoints.account.change_password}/${user_id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
            old_password,
            password,
            password2
        })
    }, true))
    return {
        handlePwdChange: async (old_password, password, password2, user_id) => {
            try {
                const res = await mutation.mutateAsync({ old_password, password, password2, user_id });
                const resData = await res.json()
                return resData
            } catch (err) {
                return false
            }
        },
        PwdChangeisError: mutation.isError,
        PwdChangeisLoading: mutation.isLoading,
    }
}


// Get User's Referrals List
export function useUsersReferralList(user_id, enabled) {
    const { accessToken } = getTokens();

    const { isLoading, isError, data, status } = useQuery(
        [USERS_REFERRALS_QUERY_KEY, enabled], async () => {
            try {
                // console.log('CALLED DIPENDED QUERY',user_id )
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.users.referrals}/${user_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                // console.log('hook data ->', data)
                const data = await res.json()
                return data
            } catch (err) {
                return undefined;
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        enabled: !!enabled,
        retry: 3,
        refetchOnMount: true, // ms
    })

    return { usersReferralData: data, usersReferralIsLoading: isLoading, isError, status }
}