import React, { useContext } from 'react'
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import UserFormContext from '../../../context/user/UserFormContext';

export default function SelectField({ question, qindex }) {
    const { answers, setAnswers } = useContext(UserFormContext);
    const handleChange = (e) => {
        let answer = [...answers];
        answer[qindex].data = [e.target.value]
        setAnswers(answer);
    };
    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <Select
                    value={answers[qindex]?.data ?? ''}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value="" disabled>
                        <em>Select</em>
                    </MenuItem>
                    {question.options.map((option, index) => (
                        <MenuItem key={index} value={option.optionText}>{option.optionText}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )
}
