import {  useRef } from "react";
// import { useAddCoupon } from "../../hooks/coupon";
// import moment from "moment";
// import CouponAddFrom from "../Form/CouponAddFrom";
// import axios from "axios";
import { toast } from 'react-toastify';
import CreatePINGroupForm from "../Form/CreatePINGroupForm";
import { useCreateLocationGroup } from "../../hooks/location";
// import { useAccountUpdate } from "../../hooks/user";
// import ProfileCreateForm from "../Forms/ProfileCreateForm"


export default function LocationGroupCreateModal() {
    const closePinGroupModal = useRef();
    const { handleCreateGroup, createGroupIsLoading } = useCreateLocationGroup()
    const handleSubmit = async (values, { resetForm }) => {

        const res = await handleCreateGroup(values)
        // console.log('category_ids -> ', category_ids.join(",").toString())
        console.log('res-> ', res)

        if (res.success){
            toast.success('New location group created.');
            closePinGroupModal.current.click();
            resetForm({values: ''});
        } else {
            toast.error(res.detail)
        }

        // const res = await handleAccountUpdate({user_profiles: [values]}, user.id)
        // if (res.is_error){
        //     setErrors({
        //         isError:res.is_error,
        //         msg: res.error
        //     })
        // } else {
        //     resetForm({values: ''})
        //     closeProfileCreateModal.current.click();
        // }
    }
    return (
        <div>
            <div className="modal fade" id="createLocGroup" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="locGroupLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="locGroupLabel">Create Location Group</h5>

                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" ref={closePinGroupModal}></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <CreatePINGroupForm
                                    isLoading={createGroupIsLoading}
                                    onSubmit={handleSubmit}
                                />
                                {/* <ProfileCreateForm 
                                    UpdateIsLoading={UpdateIsLoading}
                                    errors={errors}
                                    handleSubmit={handleSubmit}
                                />                                 */}
                            </div>
                        </div>
                        {/* <div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={closeSwitchProfileRef}>Close</button>
							<button type="button" className="btn btn-primary" onClick={handleSwitchUserSubmit}>Switch Account</button>
						</div> */}

                        {/* <div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
						</div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
