/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import './QuestionForm.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import FormFooter from './FormFooter';
import FormSidebar from './FormSidebar';
import AddQuestionField from './AddQuestionField';
import CompactQuestion from './CompactQuestion';
import AddOptionsField from './AddOptionsField';
import FormContext from '../../context/form/FormContext';
import { useGetFormDetail, useUpdateForm } from '../../hooks/form';
import { useParams } from 'react-router-dom';
// import { getFormDetail } from '../../libs/form';
import { toast } from 'react-toastify';
import FormSkeleton from '../Skeleton/FormSkeleton';
import FormTitleDescSkeleton from '../Skeleton/FormTitleDescSkeleton';
import TextareaAutosize from '@mui/material/TextareaAutosize';

export default function QuestionForm() {
    const { id } = useParams();
    const { data, isLoading, status } = useGetFormDetail(id);
    const { questions, setQuestions, fromInfo, setFormInfo } = useContext(FormContext);
    const { updateFormIsLoading, handleUpdateForm } = useUpdateForm();



    useEffect(() => {
        if( data ){
            setFormInfo(data)
            // Object.keys(data.form_data).length > 0 && setQuestions(data.form_data)
            setQuestions(data.form_data)
        }
    }, [status === 'success'])


    const handleCopyQuestion = (i) => {
        handleExpandCloseAll();
        let question = [...questions];
        const newQuestion = {...question[i], open:true};
        let qsCopy = JSON.parse(JSON.stringify(question));
        qsCopy.push(newQuestion);
        setQuestions(qsCopy)
    }
    
    const handleOnDragEnd = (result) => { 
        if (!result.destination){
            return;
        }
        const itemgg = [...questions]
        const item = reorder(
            itemgg,
            result.source.index,
            result.destination.index
        )
        setQuestions(item);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [ removed ] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }

    const handleExpandCloseAll = () => {
        let qs = [...questions];
        for (let j=0; j < qs.length; j++){
            qs[j].open = false;
        }
        setQuestions(qs);
    }
    const handleExpand = (i) => {
        let qs = [...questions];
        for (let j=0; j < qs.length; j++){
            if(i === j){
                qs[j].open = true;
            } else {
                qs[j].open = false;
            } 
        }
        setQuestions(qs);
    }

	const questionsUI = () => {
		return questions.map((question, i) => (
            <Draggable key={i} draggableId={`${i}id`} index={i}>
                {(provided, snapshot) => (
                    <div 
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <div>
                            <div style={{marginBottom: '0px'}}>
                                <div style={{width: '100%', marginBottom: '0px'}}>
                                    <DragIndicatorIcon 
                                        style={{transform: 'rotate(-90deg)', color: '#DAE0E2', position: 'relative', left: '300px'}}
                                        fontSize='small'
                                    />
                                </div>
                            </div>
                            <div>
                            <Accordion expanded={question.open} onChange={() => handleExpand(i)} className={questions[i].open ? 'add_border'  : ''}>
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    elevation={1}
                                    style={{ width: '100%'}}
                                >
                                    {!questions[i].open && 
                                        <CompactQuestion 
                                            questions={questions}
                                            question={question}
                                            i={i}
                                        />
                                    }					
                                </AccordionSummary>
                                
                                { questions[i].open &&
                                <div className='question_boxes'>
                                    <AccordionDetails className='add_question'>
                                        <AddQuestionField 
                                            question={question}
                                            i={i}
                                        />
                                        <AddOptionsField 
                                            question={question}
                                            i={i}
                                            questions={questions}
                                            setQuestions={setQuestions}
                                        />
                                        <FormFooter 
                                            form_id={id}
                                            question={question}
                                            i={i}
                                            handleCopyQuestion={handleCopyQuestion}
                                            handleSaveChangesBtn={handleSaveChanges}
                                            updateFormIsLoading={updateFormIsLoading}
                                        />
                                    </AccordionDetails>
                                    <FormSidebar 
                                        handleExpandCloseAll={handleExpandCloseAll}
                                    />
                                </div> }
                            </Accordion>
                        </div>
                        </div>
                        
                    </div>
                )}
            </Draggable>				
		))
	}

    const handleSaveChanges = async () => {
        const res = await handleUpdateForm(id, fromInfo.title, fromInfo.description, questions)
        res ? toast.info('Saved Changes.') : toast.error('Saving failed.')
    }
	return (
        <div className="question-form">
            <br />
            <div className="section">
                <div className="question-title-section">
                    <div className="question-form-top">
                    { !isLoading ? <>  
                            <input 
                                type="text" 
                                className="question-from-top-name" 
                                style={{color: 'blank'}} 
                                placeholder="Untitled survey" 
                                value={fromInfo.title}
                                onChange={(e) => setFormInfo({...fromInfo, title: e.target.value})}
                            />
                            
                            {/* <input 
                                type="text" 
                                className="question-from-top-desc" 
                                style={{color: 'blank'}} 
                                placeholder="Description" 
                                value={fromInfo.description}
                                
                            /> */}
                            <TextareaAutosize
                                placeholder="Description"
                                value={fromInfo.description}
                                onChange={(e) => setFormInfo({...fromInfo, description: e.target.value})}
                                className="question-from-top-desc" 
                            />
                        </>
                        :
                            <FormTitleDescSkeleton />
                        }
                    </div>
                </div>
                { !isLoading ?                
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {questionsUI()}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                :
                    <>
                        <FormSkeleton />
                        <FormSkeleton />
                        <FormSkeleton />
                    </>
                }
                
            </div>
        </div>
	)
}
