import React from 'react';
import DataTable from 'react-data-table-component';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const sortIcon = <KeyboardArrowDownIcon />;
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };


const customStyles = {
    rows: {
        style: {
            minHeight: '100px', // override the row height
            fontSize: '16px',
            padding: '5px'

        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};
function DataTableBase(props) {
    return (
        <DataTable            
            pagination
            selectableRowsComponent={Checkbox}
            selectableRowsComponentProps={selectProps}
            sortIcon={sortIcon}
            dense
            {...props}
            customStyles={customStyles}
        />
    );
}

export default DataTableBase;