import { Button, Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react'
import { confirmAlert } from 'react-confirm-alert';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
import DataTableBase from '../../components/Common/DataTableBase';
import Loader from '../../components/Loader';
import LocationGroupLogicModal from '../../components/Modal/LocationGroupLogicModal';
import { useDeleteGroupLogic, useGetLocGroupDetail, useGetLocGroupLogicDetail } from '../../hooks/location';
import { capitalize } from '../../utils';


const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

export default function LocationGroupLogicPage() {
    const { id } = useParams();
    const { locGroupData, locGroupIsLoading } = useGetLocGroupDetail(id);
    const { locGroupLogicData, locGroupLogicIsLoading } = useGetLocGroupLogicDetail(locGroupData && !locGroupIsLoading && locGroupData.id, !locGroupIsLoading);
    const { handleDeleteLogic, isLogicDeleting } = useDeleteGroupLogic();
    if (locGroupIsLoading) {
        return <Loader />
    }
    // console.log(locGroupData)
    // console.log('locGroupLogicData -> ', locGroupLogicData)


    const columns = [
        {
            name: '# ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Target Type',
            selector: row => capitalize(row.target_type),
            sortable: true,
        },
        {
            name: 'Code',
            selector: row => row.code,
            sortable: true,
        },
        {
            name: 'Method Type',
            selector: row => row.method_type === 'allow' ? 'Allow' : 'Block',
            sortable: true,
        },
        {
            name: 'Created at',
            selector: row => moment(row.created_at).format('DD-MM-YYYY hh:mm')
        },
        {
            name: 'Updated at',
            selector: row => moment(row.updated_at).format('DD-MM-YYYY hh:mm')
        },
        {
            name: "Buttons",
            button: true,
            width: "200px",
            cell: (row) => (<>
                <button className='btn btn-sm btn-outline-danger' onClick={() => handleDeleteGroupLogic(row.id)}>
                    delete
                </button>
            </>)
        }
    ];

    const handleDeleteGroupLogic = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const res = await handleDeleteLogic(id)
                        if (res.success) {
                            toast.success('Deleted successfully.')
                        } else {
                            toast.error(res.detail)
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => toast.info('Action cancelled.')
                }
            ]
        });
    };
    return (
        <Container maxWidth="xxl">
            {
                locGroupData ?
                    <PageBreadcrumbs
                        title="Location Groups"
                        title2={locGroupData.name}
                        path={[{ name: 'Location Groups', url: '/location/group/list' }]}
                    /> : <h1>Group not found.</h1>
            }

            <Grid container>
                <Grid item xs={12} sx={{ p: 2 }}>
                    {/* <Typography>Name: {userData.user.account_name}</Typography> */}
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div">
                                {locGroupData.name}
                            </Typography>
                            <Typography variant="body2" paragraph color="text.secondary">
                                {locGroupData.description}
                            </Typography>
                            <Typography variant='subtitle2'> Status:  {locGroupData.status ? 'Active' : 'Deactivated'}</Typography>

                            <Typography title={moment(locGroupData.created_at).format('DD-MM-YYYY hh:mm')} variant='subtitle2'>Created at: {moment(locGroupData.created_at).format('DD-MM-YYYY')}</Typography>
                            <Typography title={moment(locGroupData.updated_at).format('DD-MM-YYYY hh:mm')} variant='subtitle2'>Last updated at: {moment(locGroupData.updated_at).format('DD-MM-YYYY')}</Typography>

                        </CardContent>

                    </Card>
                </Grid>
                <Grid item xs={4} sx={{ p: 2 }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" data-bs-toggle="modal" data-bs-target="#addGroupLogic">Add new logic</Button>
                        <LocationGroupLogicModal group_id={id} />
                    </Stack>
                </Grid>
                <Grid item xs={12} sx={{ p: 2 }}>
                    <Stack direction="row" spacing={2}>
                        <p>
                            Read more about the targetting: 
                            https://economictimes.indiatimes.com/nation-world/here-is-how-your-pin-code-is-decided/first-digit/slideshow/57889801.cms
                        </p>
                    </Stack>
                </Grid>
            </Grid>

            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                {
                    locGroupLogicData && !locGroupLogicIsLoading && <DataTableBase
                        columns={columns}
                        data={locGroupLogicData}
                        direction="auto"
                        fixedHeaderScrollHeight="300px"
                        pagination
                        responsive
                        subHeaderAlign="right"
                        subHeaderWrap
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                        progressPending={isLogicDeleting}
                    />
                }
            </Grid>
        </Container>
    )
}
