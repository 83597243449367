// import CropOriginalIcon from '@mui/icons-material/CropOriginal';
// import { FormControlLabel, Button } from '@mui/material';
// import ShortTextIcon from '@mui/icons-material/ShortText';
// import CloseIcon from '@mui/icons-material/Close';
// import { IconButton } from '@mui/material';
// import { useState } from 'react';
import GridOptions from '../Section/GridOptions';
import ScaleOption from '../Section/ScaleOption';
import SimpleOptions from '../Section/SimpleOptions';

export default function AddOptionsField({ question, i, questions, setQuestions }) {

    return (
        <>
            {(question.questionType === 'CHECKBOX_GRID' || question.questionType === 'RADIO_GRID') &&
                <GridOptions
                    question={question}
                    i={i}
                    questions={questions}
                    setQuestions={setQuestions}
                />
            }

            {(question.questionType === 'TEXT' || question.questionType === 'RADIO' || question.questionType === 'CHECKBOX' || question.questionType === 'DROPDOWN') &&
                <SimpleOptions
                    question={question}
                    i={i}
                    questions={questions}
                    setQuestions={setQuestions}
                />
            }

            {question.questionType === 'SCALE_QUESTION' && <ScaleOption
                question={question}
                i={i}
                questions={questions}
                setQuestions={setQuestions}
            />}
        </>

    )
}
