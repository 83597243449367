// import { useRef } from "react";
// import { toast } from 'react-toastify';
// import { useLocationGroupLogic } from "../../hooks/location";
// import GroupLogicForm from "../Form/GroupLogicForm";
import Modal from 'react-modal';
import { useCouponDetail } from "../../hooks/coupon";
import moment from "moment";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        // right: 'auto',
        // bottom: 'auto',
        // marginRight: '-50%',
        // transform: 'translate(-50%, -50%)',
    },
};

export default function CouponInfoModal({ slug, modalIsOpen, setModalIsOpen }) {
    const { couponData, couponDataIsLoading } = useCouponDetail(slug, modalIsOpen);
    console.log(couponData)
    return (
        <Modal
            isOpen={Boolean(slug) && modalIsOpen}
            // onAfterOpen={refetch}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Coupon Detail"
        >
            <div className="container-fluid">                
                {couponDataIsLoading && <div className="d-flex justify-content-between">
                    <h5 className="modal-title">Please wait...</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModalIsOpen(false)}></button>
                </div>}
                {couponData && !couponDataIsLoading && <>
                    <div className="d-flex justify-content-between">
                        <h5 className="modal-title">{couponData.title}</h5>
                        <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModalIsOpen(false)}></button>
                    </div>
                    <div className="modal-body" style={{ width: '100%' }}>
                        <p>{couponData.description}</p>

                        <table className="table table-striped">

                            <tbody>
                                {couponData.coupon_code && <tr>
                                    <th scope="row">Coupon:</th>
                                    <td>{couponData.coupon_code}</td>
                                </tr>
                                }
                                {couponData.official_link && <tr>
                                    <th scope="row">Link:</th>
                                    <td>{couponData.official_link}</td>
                                </tr>
                                }
                                <tr>
                                    <th scope="row">Expiry Date:</th>
                                    <td>{moment(couponData.expiry_date).format('DD-MM-YYYY')}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Max Redeem Limit:</th>
                                    <td>{couponData.redeem_limit} Users</td>
                                </tr>
                                <tr>
                                    <th scope="row">Redeem Point:</th>
                                    <td>{couponData.redeem_point} Strokes</td>
                                </tr>
                                <tr>
                                    <th scope="row">Category:</th>
                                    <td>
                                        {couponData.category.map((val, i) => val.name)}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Status:</th>
                                    <td>{couponData.status ? 'Active' : 'Deactive'}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Created at:</th>
                                    <td>{moment(couponData.created_at).format('DD-MM-YYYY hh:mm')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>}
            </div>


        </Modal>
    )
}
