import React from 'react'
import DataTableBase from '../../components/Common/DataTableBase';
import Loader from '../../components/Loader';
import moment from "moment";
import { Container, Grid, IconButton, Typography } from '@mui/material';
import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
import FilterComponent from '../../components/Common/FilterComponent';
import { useFormsList, useFormTweakChange } from '../../hooks/form';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';


const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

const paginationComponentOptions = {
    rowsPerPageText: 'Show users per page',
    rangeSeparatorText: 'of',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Users',
};

export default function FavouriteFormsPage() {
    const navigate = useNavigate();

    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const { formData, formIsLoading } = useFormsList('is_fav=true');
    const { handleUpdateFavStatus, updateFavIsLoading } = useFormTweakChange();


    const handleRemoveFromFav = async (form_id) => {
        const res = await handleUpdateFavStatus(form_id, false)
        if (res) {
            toast.info('Removed from favourite.')
        } else {
            toast.error('Try again.')
        }
    }

    const columns = [
        {
            name: 'ID',
            selector: row => row.form_id
        },
        {
            name: 'Title',
            selector: row => row.title ? row.title : '-',
        },
        {
            name: 'Description',
            selector: row => row.description ? row.description : '-',

        },
        {
            name: 'Registered at',
            selector: row => moment(row.created_at).format('DD-MM-YYYY hh:mm'),
            width: '150px',
            sortable: true,
        },
        {
            name: 'Updated at',
            selector: row => moment(row.updated_at).format('DD-MM-YYYY hh:mm'),
            width: '150px',
            sortable: true,
        },
        {
            name: "Actions",
            button: true,
            width: "200px",
            cell: (row) => (<>
                <IconButton onClick={() => navigate(`/preview/${row.form_id}`)} disabled={updateFavIsLoading}>
                    <VisibilityIcon />
                </IconButton>
                <IconButton onClick={() => navigate(`/form/${row.form_id}`)} disabled={updateFavIsLoading}>
                    <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleRemoveFromFav(row.form_id)} disabled={updateFavIsLoading}>
                    <DeleteOutlineIcon style={{ color: '#dc3545' }} />
                </IconButton>

            </>)
        }
    ];

    // Table Header
    const subHeaderComponent = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    if (formIsLoading) {
        return <Loader />
    }

    // Filter data
    const filteredItems = formData.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );
    return (
        <Container maxWidth="xxl">
            <PageBreadcrumbs
                title='Favourite Forms'
                title2='Favourites'
                path={[{ name: 'Forms', url: '/' }]}
            />
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='h6'>Favourite Forms</Typography>
                    <DataTableBase
                        columns={columns}
                        data={filteredItems}
                        direction="auto"
                        fixedHeaderScrollHeight="300px"
                        pagination
                        responsive
                        subHeaderAlign="right"
                        subHeaderWrap
                        expandableRows
                        subHeader
                        striped
                        expandableRowsComponent={ExpandedComponent}
                        subHeaderComponent={subHeaderComponent}
                        paginationComponentOptions={paginationComponentOptions}
                        highlightOnHover
                        pointerOnHover
                        progressPending={formIsLoading}
                    />

                </Grid>

            </Grid>
        </Container>

    )
}
