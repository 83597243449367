import { TOKEN_STORE_NAME } from '../config'


export const getTokens = () => {
    const tokens = localStorage.getItem(TOKEN_STORE_NAME);
    if (tokens) {
        const { access, refresh } = JSON.parse(tokens)
        return {
            accessToken: access,
            refreshToken: refresh
        }
    } else {
        return {
            accessToken: '',
            refreshToken: ''
        }
    }




}
