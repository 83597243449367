import React from 'react'
import DataTableBase from '../../components/Common/DataTableBase';
import Loader from '../../components/Loader';
import { useUsersList } from '../../hooks/user';
import moment from "moment";
import { capitalize, Container, Grid } from '@mui/material';
import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
import { Link } from "react-router-dom";
import FilterComponent from '../../components/Common/FilterComponent';

const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
const paginationComponentOptions = {
    rowsPerPageText: 'Show users per page',
    rangeSeparatorText: 'of',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Users',
};
export default function UsersManagement() {
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const { usersData, usersIsLoading } = useUsersList();
    // const [user, setUser] = React.useState(null);
    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
            width: '60px'
        },
        {
            name: 'Name',
            selector: row => `${row.account_name} (${row.gender !== null ? capitalize(row.gender) : 'N/A'})`,
            sortable: true,

        },
        {
            name: 'Email',
            selector: row => row.email
        },
        {
            name: 'Stroke Balance',
            selector: row => row.stroke_balance,
            sortable: true,
            width: '100px'
        },
        {
            name: 'Badge',
            selector: row => row.user_type,
            sortable: true,
            width: '100px'
        },
        {
            name: 'Birth Year',
            selector: row => row.year_of_birth,
            sortable: true,
            width: '100px'
        },
        {
            name: 'Referral ID',
            selector: row => row.referral_id,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Registered at',
            selector: row => moment(row.created_at).format('DD-MM-YYYY hh:mm'),
            width: '150px'
        },
        {
            name: 'Updated at',
            selector: row => moment(row.updated_at).format('DD-MM-YYYY hh:mm'),
            width: '150px'
        },
        {
            name: "Buttons",
            button: true,
            width: "200px",
            cell: (row) => (<>
                <Link to={`/user/detail/${row.username}`} className='btn btn-sm btn-outline-dark mx-2'>
                    View Details
                </Link>
                {/* <button className='btn btn-sm btn-outline-danger'>
                    Ban
                </button> */}
            </>)
        }
    ];

    // Table Header
    const subHeaderComponent = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    if (usersIsLoading) {
        return <Loader />
    }

    // Filter data
    const filteredItems = usersData.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );
    return (
        <Container maxWidth="xxl">
            <PageBreadcrumbs
                title='Users Management'
                title2='Users'
            // path={[{name: 'test',url:'/'}, {name: 'test2',url:'/'}]}
            />
            <Grid container>
                <Grid item xs={12} sx={{ p: 2 }}>
                    {
                        usersData && !usersIsLoading && <DataTableBase
                            columns={columns}
                            data={filteredItems}
                            direction="auto"
                            fixedHeaderScrollHeight="300px"
                            pagination
                            responsive
                            subHeaderAlign="right"
                            subHeaderWrap
                            expandableRows
                            subHeader
                            striped
                            expandableRowsComponent={ExpandedComponent}
                            subHeaderComponent={subHeaderComponent}
                            paginationComponentOptions={paginationComponentOptions}
                            highlightOnHover
                            pointerOnHover
                        />
                    }
                </Grid>

            </Grid>
        </Container>

    )
}
