import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Loader() {
	return (
		<Box sx={{ width:'100%', display: 'flex', alignItems: 'center', alignContent: 'center', padding: 10 }}>
			<CircularProgress />
		</Box>
	);
}