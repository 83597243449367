import { useRef, useState } from "react";
import PasswordChangeForm from "../Form/PasswordChangeForm";
import { usePasswordChange } from "../../hooks/user";
import { toast } from 'react-toastify';



export default function PasswordChangeModal({ user }) {
    const closePasswordChangeModal = useRef();
    const { handlePwdChange, PwdChangeisLoading } = usePasswordChange();
    const [response, setResponse] = useState(null)
    const handleSubmit = async (values, { resetForm }) => {
        const { old_password, password, password2 } = values;

        const res = await handlePwdChange(old_password, password, password2, user?.id);
        setResponse(res)
        if (res.success) {
            toast.success(res.detail)
            closePasswordChangeModal.current.click();
            resetForm({ values: '' });
        } else {
            toast.error(res.detail)
        }
    }
    return (
        <div>
            <div className="modal fade" id="changePassword" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="changePasswordLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="changePasswordLabel">Change Password</h5>

                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" ref={closePasswordChangeModal}></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <PasswordChangeForm
                                    isLoading={PwdChangeisLoading}
                                    onSubmit={handleSubmit}
                                    response={response}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
