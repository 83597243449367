import React, { useContext } from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import UserFormContext from '../../../context/user/UserFormContext';


export default function RadioField({ question, qindex }) {
    // const [value, setValue] = React.useState('female');
    const { answers, setAnswers } = useContext(UserFormContext);
    const handleChange = (e) => {
        let answer = [...answers];
        answer[qindex].data = [ e.target.value ]
        setAnswers(answer);
        // console.log(answers[qindex].data.value)
    };
    return (
        <FormControl>
            <RadioGroup
                name={qindex.toString()}
                value={answers[qindex]?.data ?? ''}
                onChange={handleChange}
            >
                {question.options.map((option, index) => (
                    <FormControlLabel key={index} value={option.optionText} control={<Radio />} label={option.optionText} />
                ))}

            </RadioGroup>
        </FormControl>

    )
}
