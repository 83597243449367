import React from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import moment from "moment";


export default function DatePicker({ label, ...props }) {
    const [field, meta, helpers] = useField(props);
    // console.log('field -> ', field);
    // console.log('meta -> ', meta);
    // console.log('props -> ', props);
    return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    // onChange={(option) => helpers.setValue(moment(option).format('YYYY-MM-DD'))}
                    onChange={(option) => helpers.setValue(option)}
                    label={label}                    
                    // inputFormat="DD/MM/YYYY"
                    renderInput={(params) => <TextField variant='standard' fullWidth {...field} {...params} autoComplete='off' error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error} />}
                    {...meta}
                    {...props}
                />
            </LocalizationProvider>
    )
}