import React, {useContext} from 'react'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import UserFormContext from '../../../context/user/UserFormContext';

export default function SliderField({ question, qindex }) {
	const { min, max, interval } = question.questionGridItem.scale

	const { answers, setAnswers } = useContext(UserFormContext);
	const marks = [
		{
			value: parseFloat(min),
			label: 'Min',
		},
		{
			value: parseFloat(max),
			label: 'Max',
		},
	];
	const handleSlide = (e) => {
		// console.log(e.target.value)
		let answer = [...answers];
        answer[qindex].data = [ e.target.value ]
        setAnswers(answer);
	}
	return (
		<Box>
			<Slider
				// valueLabelDisplay="auto"
                valueLabelDisplay="on"
				step={parseFloat(interval)}
				marks={marks}
				min={parseFloat(min)}
				max={parseFloat(max)}
				onChange={handleSlide}
			/>
		</Box>
	)
}
