import React from 'react'
import Main from '../components/Main'
import Template from '../components/Template'

export default function HomePage() {
	return (
		<>
			<Template />
			<Main />
		
		</>
	)
}
