import React from 'react'
import './Header.css'
import { IconButton } from '@mui/material';
import { NavLink } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import DrawerComp from '../Drawer';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { StyledMenu } from '../Styled/StyledMenu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import PasswordIcon from '@mui/icons-material/Password';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLogout } from '../../hooks/user';
import { useNavigate } from 'react-router-dom';


export default function Header({ user, isLoading }) {
    let navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Logout
    const logoutAccount = useLogout()
    const handleLogout = async () => {
        const is_logged_out = await logoutAccount();
        console.log(is_logged_out)
        if (is_logged_out) {
            navigate(`/account/login`);
        }
    }
    return (
        <div className="header">
            <div className='header_info'>

                <DrawerComp />
                <NavLink to='/' style={({ isActive }) => { return { color: isActive ? '#584848' : '#1976d2', textDecoration: 'none' } }}>
                    <Typography variant="h5">Echo Strokes</Typography>
                </NavLink>

            </div>
            {/* <div className='header_search'>
				<IconButton>
					<SearchIcon />
				</IconButton>
				<input type="text" name="search" placeholder="Search" />
			</div> */}
            <div className='header_right'>
                <Stack direction="row" spacing={2}>
                    <IconButton
                        id="user-menu-button"
                        aria-controls={open ? 'user-menus' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <Avatar>{user && !isLoading && user.account_name.charAt(0).toUpperCase()}</Avatar>
                    </IconButton>
                </Stack>
                <StyledMenu
                    id="user-menus"
                    MenuListProps={{
                        'aria-labelledby': 'user-menu-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <Typography variant="p" sx={{ mx: 1 }}>{user && !isLoading && user.account_name}</Typography>

                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={handleClose} data-bs-toggle="modal" data-bs-target="#changePassword" disableRipple>
                        <PasswordIcon />
                        Change Password
                    </MenuItem>
                    <MenuItem onClick={handleLogout} disableRipple>
                        <LogoutIcon />
                        Logout
                    </MenuItem>
                </StyledMenu>
            </div>
        </div>
    )
}
