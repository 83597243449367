// import { capitalize } from '@mui/material';
import moment from 'moment';
import React from 'react'
import DataTableBase from '../Common/DataTableBase';

const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

export default function CouponRedemptionTable({ data, isLoading }) {
    console.log(data)
    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
            width: '60px'
        },
        {
            name: 'Coupon',
            selector: row => row.coupon.title,
            // width: '200px'
        },
        {
            name: 'Redeem Date',
            selector: row => moment(row.created_at).format('DD-MM-YYYY'),
            width: '200px'
        },
        {
            name: 'Expire Date',
            selector: row => moment(row.updated_at).format('DD-MM-YYYY hh:mm:ss'),
            width: '200px'
        },
    ];
    return (
        <DataTableBase
            title={data?.success ? 'Coupon Redemptions' : data?.detail}
            // title='Stroke Transactions' 
            columns={columns}
            data={data?.data}
            progressPending={isLoading}
            direction="auto"
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderAlign="right"
            subHeaderWrap
            expandableRows
            expandableRowsComponent={ExpandedComponent}
        />
    )
}
