import React, { useState, Fragment } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import RedeemIcon from '@mui/icons-material/Redeem';
import MenuItem from './MenuItem';
import FavoriteIcon from '@mui/icons-material/Favorite';


export default function DrawerComp() {
    const [state, setState] = useState({
        left: false,
    })
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem>
                    <ListItemText primary={`ECHO STROKES SURVEY`} />
                </ListItem>
                <Divider />
                <MenuItem
                    primary={`Dashboard`}
                    to='/'
                    icon={<DashboardIcon />}
                />
                <Divider />
                <MenuItem
                    primary={`Favourites`}
                    to='/form/favourites'
                    icon={<FavoriteIcon />}
                />
                <Divider />
                <ListItem>
                    <ListItemText
                        primary="Users Management"
                        primaryTypographyProps={{
                            fontSize: 15,
                            fontWeight: 'medium',
                            lineHeight: '20px',
                            mb: '2px',
                            // align: 'right',
                            color: '#636363'
                        }}
                        // secondary="Browse, add, modify & remove."
                        // secondaryTypographyProps={{
                        //     noWrap: true,
                        //     fontSize: 12,
                        //     lineHeight: '16px',
                        // }}
                        sx={{ my: 0 }}
                    />
                </ListItem>
                <Divider />
                <MenuItem
                    primary={`Users`}
                    to='/user/list'
                    icon={<PeopleOutlineIcon />}
                />
                <Divider />
                <ListItem>
                    <ListItemText
                        primary="Coupon Management"
                        primaryTypographyProps={{
                            fontSize: 15,
                            fontWeight: 'medium',
                            lineHeight: '20px',
                            mb: '2px',
                            // align: 'right',
                            color: '#636363'
                        }}
                        // secondary="Browse, add, modify & remove."
                        // secondaryTypographyProps={{
                        //     noWrap: true,
                        //     fontSize: 12,
                        //     lineHeight: '16px',
                        // }}
                        sx={{ my: 0 }}
                    />
                </ListItem>
                <Divider />
                <MenuItem
                    primary={`Coupons`}
                    to='/coupon/list'
                    icon={<RedeemIcon />}
                />
                <MenuItem
                    primary={`Category`}
                    to='/coupon/category/list'
                    icon={<CategoryIcon />}
                />
                <Divider />
                <ListItem>
                    <ListItemText
                        primary="Geo Locations"
                        primaryTypographyProps={{
                            fontSize: 15,
                            fontWeight: 'medium',
                            lineHeight: '20px',
                            mb: '2px',
                            color: '#636363'
                        }}
                        sx={{ my: 0 }}
                    />
                </ListItem>
                <Divider />
                <MenuItem
                    primary={`Location Groups`}
                    to='/location/group/list'
                    icon={<WorkspacesIcon />}
                />
                {/* <ListItem disablePadding>
					<ListItemButton component={NavLink} to="/">
						<ListItemIcon>
							<PollIcon />
						</ListItemIcon>
						<ListItemText primary="Surveys" />
					</ListItemButton>
				</ListItem>
                <ListItem disablePadding>
					<ListItemButton>
						<ListItemIcon>
							<PeopleOutlineIcon />
						</ListItemIcon>
						<ListItemText primary={`Users`} />
					</ListItemButton>
				</ListItem>                 */}
            </List>
            {/* <Divider /> */}
            {/* <List>

                <ListItem disablePadding>
					<ListItemButton>
						<ListItemIcon>
							<FavoriteIcon />
						</ListItemIcon>
						<ListItemText primary={`Favorites`} />
					</ListItemButton>
				</ListItem>
				<ListItem disablePadding>
					<ListItemButton>
						<ListItemIcon>
							<RedeemIcon />
						</ListItemIcon>
						<ListItemText primary={`Stroke Redeems`} />
					</ListItemButton>
				</ListItem>
				<ListItem disablePadding>
					<ListItemButton>
						<ListItemIcon>
							<SupervisorAccountIcon />
						</ListItemIcon>
						<ListItemText primary={`Roles`} />
					</ListItemButton>
				</ListItem>
				<ListItem disablePadding>
					<ListItemButton>
						<ListItemIcon>
							<SettingsIcon />
						</ListItemIcon>
						<ListItemText primary={`Settings`} />
					</ListItemButton>
				</ListItem>
			</List> */}
        </Box>
    )
    return (
        <div>
            <Fragment>
                <IconButton onClick={toggleDrawer('left', true)}>
                    <MenuIcon />
                </IconButton>
                <Drawer
                    open={state['left']}
                    onClose={toggleDrawer("left", false)}
                    anchor={`left`}
                >
                    {list('left')}
                </Drawer>
            </Fragment>
        </div>
    )
}
