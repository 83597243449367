export const endpoints = {
    account: {
        login: 'admin/account/v1/login', // email / username / mobile :: password 
        logout: 'accounts/v1/user/logout', 
        user: 'accounts/v1/user',         
        change_password: 'accounts/v1/user/password/change',        
    },
    survey: {
        forms_list: 'admin/survey/v1/list/forms', 
        submission_list: 'survey/v1/submission/list', 
    },
    users: {        
        list: 'admin/account/v1/users',  
        stroke_transactions: 'admin/survey/v1/users/transactions',  
        submissions: 'admin/survey/v1/users/submissions',  
        referrals: 'admin/account/v1/users/referrals', 
    },
    coupons: {        
        list: 'admin/coupon/v1/list/coupons',  
        category: 'admin/coupon/v1/category', 
        redemptions: 'admin/coupon/v1/list/redemptions', 
         
    },
    location: {        
        groups: 'admin/location/v1/list/groups',  
        groups_logic: 'admin/location/v1/list/groups/logic',  
    },
    
    
    
    
};
