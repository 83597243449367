/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import UserFormContext from '../../../context/user/UserFormContext';
import { checkHasValue } from '../../../utils';

export default function CheckboxGrid({ question, qindex }) {
    // console.log('data -> ', question.questionGridItem)
	const { rows, columns, onePerColumn, transpose } = question.questionGridItem;
    const { answers, setAnswers } = useContext(UserFormContext);
    const [selectedValue, setSelectedValue] = React.useState({});
    let checkedOptionIndexs  = []; // For validating limit one per column

    const handleChange = (title, value, rid) => {
        let prevValues = selectedValue['row' + rid]?.value ?? ''
        if (checkHasValue(prevValues, value)){
            prevValues = prevValues.splice(prevValues.indexOf(value), 1)
            setSelectedValue({
                ...selectedValue,
            });
        } else {
            setSelectedValue({
                ...selectedValue,
                ['row' + rid ]: {
                    name: title,
                    value: [...prevValues, value]
                }
            });
        }
        // console.log('selectedValue -> ', selectedValue);
    };
    useEffect(() => {
        if (answers && selectedValue.length > 0) {
            let answer = [...answers];

            answer[qindex].data = selectedValue;
            setAnswers(answer);
        }
        
        // console.log(selectedValue)
        // console.log('isObjectEmpty -> ', isObjectEmpty(selectedValue))
        // console.log(selectedValue.length)

    }, [selectedValue])
    // }, [isObjectEmpty(selectedValue)])
    // console.log(question.questionText, '-->' ,checkedOptionIndexs)
	return (
		<div>
            {!transpose ? 
                <table style={{ width: '100%',}}>
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            {columns.options.map((column, i) => (
                                <th scope="col" key={i}>{column.value}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, i) => (
                            <React.Fragment key={i}>
                                <tr>							
                                    <th scope="row">{row.title}</th>
                                    {columns.options.map((column, j, arr) => {
                                        const isCurrent = checkHasValue(selectedValue['row'+i]?.value, column.value)
                                        if(isCurrent){
                                            checkedOptionIndexs.push(j)
                                        }
                                        return <th scope="row" key={j}>
                                            <Checkbox
                                                checked={isCurrent}
                                                onChange={() => handleChange(row.title, column.value, i)}
                                                value={column.value}
                                                inputProps={{ 'aria-label': row.title }}
                                            />
                                        </th>
                                    })}
                                </tr>
                                <tr>
                                    <td colSpan={100}>
                                        {question.required && typeof selectedValue[row.title] !== 'undefined' && selectedValue[row.title].length === 0 && 
                                            <FormHelperText error={true}>Required Question*</FormHelperText>
                                        }
                                        {onePerColumn && !checkedOptionIndexs.every((e, i, a) => a.indexOf(e) === i) && 
                                            <FormHelperText error={true}>
                                                One column can be selected for every row.
                                            </FormHelperText>
                                        }
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            :
                <table style={{ width: '100%',}}>
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            {rows.map((row, i) => (
                                <th scope="col" key={i}>{row.title}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {columns.options.map((column, i) => (
                            <React.Fragment key={i}>
                                <tr>							
                                    <th scope="row">{column.value}</th>
                                    {rows.map((row, j, arr) => {
                                        const isCurrent = checkHasValue(selectedValue['row'+i]?.value, column.value)
                                        if(isCurrent){
                                            checkedOptionIndexs.push(j)
                                        }
                                        return <th scope="row" key={j}>
                                            <Checkbox
                                                checked={isCurrent}
                                                onChange={() => handleChange(row.title, column.value, i)}
                                                value={column.value}
                                                inputProps={{ 'aria-label': row.title }}
                                            />
                                        </th>
                                    })}
                                </tr>
                                <tr>
                                    <td colSpan={100}>
                                        {question.required && typeof selectedValue[column.value] !== 'undefined' && selectedValue[column.value].length === 0 && 
                                            <FormHelperText error={true}>Required Question*</FormHelperText>
                                        }
                                        {onePerColumn && !checkedOptionIndexs.every((e, i, a) => a.indexOf(e) === i) && 
                                            <FormHelperText error={true}>
                                                One column can be selected for every row.
                                            </FormHelperText>
                                        }
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            }
		</div>
	)
}
