import React from 'react'
import Grid from '@mui/material/Unstable_Grid2';
import { Container, Typography } from '@mui/material';
import LoginForm from '../../components/Form/LoginForm';
import { useLogin, useUser } from '../../hooks/user';
import { useNavigate, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getTokens } from '../../utils/shortcuts';

export default function LoginPage() {
    let navigate = useNavigate();
    const { handleLogin, loginLoading, loginError } = useLogin();
    const { accessToken, refreshToken } = getTokens();
    const { user, status } = useUser();
    const [response, setResponse] = React.useState({})

    if (accessToken && refreshToken && user && status === 'success') {
        return <Navigate to='/' />
    }

    const handleSubmit = async (values) => {
        const { username, password } = values;
        const res = await handleLogin(username, password);
        res && setResponse(res)
        if (res.status === 200) {
            toast.success('Login successfull')
            navigate(`/`);
        } else {
            toast.error(res.detail)
        }
    }
    return (
        <Container fixed>
            <Grid container>
                <Grid item xs={6} sx={{ mt: 10 }}>
                    <Typography variant='h4' sx={{ mb: 5 }}>Administration Login</Typography>
                    <LoginForm
                        loginError={loginError}
                        response={response}
                        loginLoading={loginLoading}
                        handleSubmit={handleSubmit}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}
