import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDeleteCoupon } from '../../hooks/coupon';
import { toast } from 'react-toastify';
import { smartTrim } from '../../utils';
import { confirmAlert } from 'react-confirm-alert';
// import CouponUpdateModal from '../Modal/CouponUpdateModal';
// import Modal from 'react-modal';



export default function CouponCard({ data, setUpdateSlug, setInfoModalIsOpen }) {
    const { handleDeleteCoupon } = useDeleteCoupon();

    // const handleDelete = async () => {
    //     handleClose();
    //     const res = await handleDeleteCoupon(data.slug)
    //     if (res.success) {
    //         toast.success('Deleted successfully.')
    //     } else {
    //         toast.error(res.detail)
    //     }

    // }
    
    // Delete Action
    const handleDelete = () => {
        handleClose();
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const res = await handleDeleteCoupon(data.slug)
                        if (res.success) {
                            toast.success('Deleted successfully.')
                        } else {
                            toast.error(res.detail)
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        toast.info('Action cancelled.')
                    }
                }
            ]
        });
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Grid item md={2}>
            <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                    component="img"
                    height="140"
                    image={data.img}
                    alt="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                        {data.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {smartTrim(data.description, 120)}
                    </Typography>
                </CardContent>
                <CardActions>
                    {/* <Button size="small">Share</Button> */}
                    <Button size="small" onClick={() => { setUpdateSlug(data.slug); setInfoModalIsOpen(true); }}>Show Info</Button>
                    <Button
                        id="options-button"
                        aria-controls={open ? 'options-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        Options
                    </Button>
                    <Menu
                        id="options-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'options-button',
                        }}
                    >
                        <MenuItem 
                            onClick={() => { setUpdateSlug(data.slug); handleClose(); }} 
                            data-bs-toggle="modal" 
                            data-bs-target="#updateCoupon">Edit
                        </MenuItem>
                        <MenuItem onClick={handleDelete}>Delete</MenuItem>
                        
                    </Menu>
                </CardActions>
            </Card>
            
        </Grid>
    );
}
