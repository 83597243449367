import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import { Link as RouterLink } from 'react-router-dom';

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

export default function PageBreadcrumbs({ title, title2, path  }) {
    return (
        <div role="presentation" onClick={handleClick}>
        <Divider sx={{ mt: 1 }} />
            <Typography variant="h5">{title}</Typography>
            <Breadcrumbs aria-label="breadcrumb">

                <Link underline="hover" component={RouterLink} variant="button" color="inherit" to="/">
                    Home
                </Link>\
                {path &&
                    path.map((v, i) => (
                        <Link
                            underline="hover"
                            color="inherit"
                            component={RouterLink}
                            to={v.url}
                            key={i}
                        >
                            {v.name}
                        </Link>
                    ))
                }


                <Typography color="text.primary">{title2 ? title2 : title}</Typography>
            </Breadcrumbs>
            <Divider sx={{ mb: 5 }} />
        </div>
    )
}
