import { useRef } from "react";
// import { useAddCoupon } from "../../hooks/coupon";
import moment from "moment";
import CouponAddFrom from "../Form/CouponAddFrom";
import axios from "axios";
import { toast } from 'react-toastify';
import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";
// import { useAccountUpdate } from "../../hooks/user";
// import ProfileCreateForm from "../Forms/ProfileCreateForm"



export default function CouponAddModal({ refetchCoupons }) {
    

    const closeCouponAddModal = useRef();
    // const { handleAddNewCoupon, couponAddIsLoading } = useAddCoupon()
    // const [ errors, setErrors]=useState({
    //     isError: false,
    //     msg: ''
    // })
    const handleSubmit = async (values, { resetForm }) => {
        const { title, description, redeem_point, redeem_limit, coupon_code, official_link, img, location_group, category, expiry_date } = values;

        let categoryies = [];
        category.map((data) => categoryies.push(data.value));
        const category_ids = categoryies.join(",").toString()


        let formData = new FormData()
        formData.append('title', title)
        formData.append('description', description)
        formData.append('redeem_point', redeem_point)
        formData.append('redeem_limit', redeem_limit)
        formData.append('coupon_code', coupon_code)
        formData.append('official_link', official_link)
        formData.append('img', img)
        formData.append('location_group', location_group)
        formData.append('category_ids', category_ids)
        formData.append('expiry_date', moment(expiry_date).format('YYYY-MM-DD'))
        formData.append('status', true)

        // for ( var key in item ) {
        //     formData.append(key, item[key]);
        // }

        // const res = await handleAddNewCoupon(formData)
        // console.log('category_ids -> ', category_ids.join(",").toString())
        // console.log('res-> ', res)


        axios.post(`${API_HOST_URL}/${endpoints.coupons.list}`, formData, {
            onUploadProgress: (e) => {
                let percent = Math.round((100 * e.loaded) / e.total)
                console.log(percent)
            },
        })
            .then((res) => {
                // console.log(res)
                if (res.status === 201){
                    refetchCoupons();
                    toast.success('Coupon has been added.')
                    closeCouponAddModal.current.click();
                    resetForm({values: ''});
                    
                } else {
                    toast.error('Something wents wrong.')
                }
            });

        // const res = await handleAccountUpdate({user_profiles: [values]}, user.id)
        // if (res.is_error){
        //     setErrors({
        //         isError:res.is_error,
        //         msg: res.error
        //     })
        // } else {
        //     resetForm({values: ''})
        //     closeProfileCreateModal.current.click();
        // }
    }
    return (
        <div>
            <div className="modal fade" id="addCoupon" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="addCouponLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addCouponLabel">Add new coupon</h5>

                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" ref={closeCouponAddModal}></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <CouponAddFrom
                                    isLoading={false}
                                    onSubmit={handleSubmit}
                                />
                                {/* <ProfileCreateForm 
                                    UpdateIsLoading={UpdateIsLoading}
                                    errors={errors}
                                    handleSubmit={handleSubmit}
                                />                                 */}
                            </div>
                        </div>
                        {/* <div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={closeSwitchProfileRef}>Close</button>
							<button type="button" className="btn btn-primary" onClick={handleSwitchUserSubmit}>Switch Account</button>
						</div> */}

                        {/* <div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
						</div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
