import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import TextField from '../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import { Stack } from '@mui/material';


export default function LoginForm({ response, loginLoading, handleSubmit }) {
    const validate = Yup.object({
        username: Yup.string()
            // .min(4, 'Username must be at least 4 characters.')
            .required('Enter your username.'),
        password: Yup.string()
            // .min(6, 'Password must be at least 6 characters.')
            .required('Enter your password.'),
    })
    return (
        <Formik
            initialValues={{
                username: '',
                password: '',
            }}
            validationSchema={validate}
            onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
        >
            <Form>
                <TextField label='Username' name='username' placeholder='Email / Username' sx={{ mb: 3 }} />
                <TextField label='Password' type="password" name='password' placeholder='Your Password' sx={{ mb: 3 }} />

                <LoadingButton
                    loading={loginLoading}
                    variant="outlined"
                    type="submit"
                >
                    Login
                </LoadingButton>

                <Stack sx={{ width: '100%', mt:3 }}>
                    {response.status === 401 && <Alert severity="error">{response.detail} </Alert>}
                    {response.status === 200 && <Alert severity="success"> Login Success!</Alert>}
                </Stack>
            </Form>
        </Formik>
    )
}
