import React from 'react'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CouponCard from '../../components/Coupon/CouponCard';
import { Button, Stack } from '@mui/material';
import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
import { useCouponsList } from '../../hooks/coupon';
import CouponAddModal from '../../components/Modal/CouponAddModal';
import CouponUpdateModal from '../../components/Modal/CouponUpdateModal';
import CouponInfoModal from '../../components/Modal/CouponInfoModal';
import { Link } from 'react-router-dom';
// import Modal from 'react-modal';

export default function CouponsListPage() {
    const { couponsData, couponsIsLoading, refetchCoupons } = useCouponsList();
    const [updateSlug, setUpdateSlug] = React.useState(null);
    const [infoModalIsOpen, setInfoModalIsOpen] = React.useState(false);
    
    // console.log(couponsData)
    return (
        <Container maxWidth="xxl">

            <PageBreadcrumbs
                title='Coupons Management'
                title2='Coupons'
            // path={[{name: 'test',url:'/'}, {name: 'test2',url:'/'}]}
            />
            <Stack direction="row" spacing={2}  sx={{ mb: 2 }}>
                <Button variant="contained" data-bs-toggle="modal" data-bs-target="#addCoupon">Add new coupon</Button>
                <Link to='/coupon/category/list'>
                    <button className='btn btn-outline-primary'>Manage Categories</button>
                </Link>
                <CouponAddModal refetchCoupons={refetchCoupons} />
                <CouponUpdateModal slug={updateSlug} />
                <CouponInfoModal 
                    slug={updateSlug}
                    modalIsOpen={infoModalIsOpen}
                    setModalIsOpen={setInfoModalIsOpen}
                />
            </Stack>
            <Grid sx={{ flexGrow: 1, mt: 3 }} container spacing={2}>
                {couponsData && !couponsIsLoading &&
                    couponsData.map((data, i) => (
                        <CouponCard
                            key={i}
                            data={data}
                            setInfoModalIsOpen={setInfoModalIsOpen}
                            setUpdateSlug={setUpdateSlug}
                        />
                    ))
                }
            </Grid>
        </Container>

    )
}
