import React from 'react'
import TextField from '../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
// import Alert from '@mui/material/Alert';
// import { Checkbox, FormControlLabel, Stack, TextareaAutosize } from '@mui/material';
import TextAreaField from '../Common/Fields/TextAreaField';
// import DatePickerField from '../Common/Fields/DatePickerField';
// import RadioField from '../Common/Fields/RadioField';
// import SelectField from '../Common/Fields/SelectField';
// import { stripLocationGroups, useLocationGroups } from '../../hooks/location';
// import Select from 'react-select'
// import AsyncSelect from 'react-select/async';
// import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
// import PhotoCamera from '@mui/icons-material/PhotoCamera';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
// import { Typography } from '@mui/material';
// import FormHelperText from '@mui/material/FormHelperText';
// import { stripCouponsCategory, useCouponsCategoryList } from '../../hooks/coupon';
// import { getCurrentDate } from '../../utils';
// import dayjs from 'dayjs';


export default function CreatePINGroupForm({ isLoading, onSubmit }) {
    
    // console.log(couponCategoryData.map(stripCouponsCategory))
    const validate = Yup.object({
        name: Yup.string()
            // .min(4, 'Username must be at least 4 characters.')
            .required('Identifier is required.'),      
    })
    return (
        <Formik
            initialValues={{
                name: '',
                description: '',
                status: true,
            }}
            validationSchema={validate}
            onSubmit={onSubmit}
        >
            {formik => (
                <Form>
                    <TextField label='Group Name' name='name' placeholder='Group Name' sx={{ mb: 3 }} />
                    <TextAreaField label='Description' name='description' placeholder='Description' sx={{ mb: 3 }} />
                                    
                    {/* <SelectField label="Zone Group" name="location_group" sx={{ mb: 3 }} /> */}
                    {/* <Stack sx={{ mt: 3 }} spacing={1}>
                    <Typography variant="h9" color="#949494">
                        Select Location Group
                    </Typography> */}
                    {/* {locationGroupsData && !locationGroupsIsLoading && <Select
                        options={locationGroupsData.map(stripLocationGroups)}
                        // onChange={(e) => console.log(e.value)}
                        onChange={(e) => formik.setFieldValue('location_group', e.value)}
                    />} */}

                    {/* <Typography variant="h9" color="#949494" sx={{ mt: 3 }}>
                        Select Category
                    </Typography> */}
                    {/* {couponCategoryData && !couponCategoryIsLoading && <Select
                        options={couponCategoryData.map(stripCouponsCategory)}
                        // onChange={(e) => console.log(e)}
                        isMulti
                        onChange={(e) => formik.setFieldValue('category', e)}
                    />} */}
                    {/* </Stack> */}

                    

                    {/* <RadioField 
                    options={['Yes', 'No']}
                    name='status'
                /> */}
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        type="submit"
                        disableElevation
                    >
                        Create
                    </LoadingButton>

                    {/* <Stack sx={{ width: '100%', mt: 3 }}>
                    {response.status === 401 && <Alert severity="error">{response.detail} </Alert>}
                    {response.status === 200 && <Alert severity="success"> Login Success!</Alert>}
                </Stack> */}
                </Form>
            )}

        </Formik>
    )
}
