import React from 'react'
// import QuestionForm from '../components/Form/QuestionForm'
import TabPanel from '../../components/AdminForm/Tabs'

export default function FormPage() {
	return (
		<>
            <TabPanel />
            {/* <QuestionForm /> */}
        </>
	)
}
