import React from 'react';
import TextField from '../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import RadioField from '../Common/Fields/RadioField';
import { Stack } from '@mui/material';

export default function AddCouponCategoryForm({ isLoading, onSubmit }) {
    const validate = Yup.object({
        name: Yup.string().required(),
        order: Yup.string().required()
    })
    return (
        <Formik
            initialValues={{
                name: '',
                order: '',
                status: true,
            }}
            validationSchema={validate}
            onSubmit={onSubmit}
        >
            <Form>
                {/* {console.log(formik)} */}
                <TextField label='Title' name='name' placeholder='Title' sx={{ mb: 3 }} />
                <TextField label='Order' type='number' name='order' placeholder='Order Number' sx={{ mb: 3 }} />
                <Stack direction="row">
                    <RadioField
                        options={['Active', 'Deactive']}
                        name='status'
                    />
                </Stack>

                <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    type="submit"
                    disableElevation
                    sx={{ mt: 2 }}
                >
                    Add
                </LoadingButton>
            </Form>
        </Formik>
    )
}
