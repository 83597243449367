/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useContext} from 'react'
import Radio from '@mui/material/Radio';
import FormHelperText from '@mui/material/FormHelperText';
import UserFormContext from '../../../context/user/UserFormContext';
// import { isObjectEmpty } from '../../../utils';

export default function RadioGrid({ question, qindex }) {
	const { rows, columns, onePerColumn, transpose } = question.questionGridItem;
    const { answers, setAnswers } = useContext(UserFormContext);
    const [ selectedValue, setSelectedValue ] = useState({});
    let checkedOptionIndexs  = []; // For validating limit one per column

    const handleChange = (title, value, rid) => {
        // let prevValues = selectedValue[title] ?? ''
        let prevValues = selectedValue['row' + rid]?.value ?? ''
        if (prevValues === value) {
            setSelectedValue({
                ...selectedValue,
                // [title]: ''
                ['row' + rid ]: {
                    name: title,
                    value: ''
                }
            });
        } else {
            setSelectedValue({
                ...selectedValue,
                // [title]: value
                ['row' + rid ]: {
                    name: title,
                    value: value
                }
            });
            setSelectedValue({
                ...selectedValue,
                ['row' + rid ]: {
                    name: title,
                    value: value
                }
            });
        }
        // console.log(selectedValue);
    };    

    useEffect(() => {
        if (answers && selectedValue.length > 0){
            let answer = [...answers];
            answer[qindex].data = selectedValue;
            
            setAnswers(answer);
        }
        // console.log(selectedValue)
        // console.log('isObjectEmpty -> ', isObjectEmpty(selectedValue))
        // console.log(selectedValue.length)
        
    }, [selectedValue])
	return (
		<div>
            {!transpose ? 
                <table style={{ width: '100%',}}>
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            {columns.options.map((column, i) => (
                                <th scope="col" key={i}>{column.value}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, i) => (
                            <React.Fragment key={i}>
                                <tr>							
                                    <th scope="row">{row.title}</th>
                                    {columns.options.map((column, j, arr) => {
                                        if(selectedValue['row' + i]?.value === column.value){
                                            checkedOptionIndexs.push(j)
                                        }
                                        return <th scope="row" key={j}>
                                            <Radio
                                                checked={selectedValue['row' + i]?.value === column.value}
                                                onClick={() => handleChange(row.title, column.value, i)}
                                                value={column.value}
                                                name={row.title}
                                                inputProps={{ 'aria-label': row.title }}
                                            />
                                        </th>
                                    })}                            
                                </tr>
                                <tr>
                                    <td colSpan={100}>
                                        {question.required && selectedValue[row.title] === '' && <FormHelperText error={true}>
                                            Required Question*
                                        </FormHelperText>}
                                        {onePerColumn && !checkedOptionIndexs.every((e, i, a) => a.indexOf(e) === i) && <FormHelperText error={true}>
                                            One column can be selected for every row.
                                        </FormHelperText>}
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            :
            <table style={{ width: '100%',}}>
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            {rows.map((row, i) => (
                                <th scope="col" key={i}>{row.title}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {columns.options.map((column, i) => (
                            <React.Fragment key={i}>
                                <tr>							
                                    <th scope="row">{column.value}</th>
                                    {rows.map((row, j, arr) => {
                                        if(selectedValue['row' + i]?.value === column.value){
                                            checkedOptionIndexs.push(j)
                                        }
                                        return <th scope="row" key={j}>
                                            <Radio
                                                checked={selectedValue['row' + i]?.value === column.value}
                                                onClick={() => handleChange(row.title, column.value, i)}
                                                value={column.value}
                                                name={row.title}
                                                inputProps={{ 'aria-label': row.title }}
                                            />
                                        </th>
                                    })}                            
                                </tr>
                                <tr>
                                    <td colSpan={100}>
                                        {question.required && selectedValue[column.value] === '' && <FormHelperText error={true}>
                                            Required Question*
                                        </FormHelperText>}
                                        {onePerColumn && !checkedOptionIndexs.every((e, i, a) => a.indexOf(e) === i) && <FormHelperText error={true}>
                                            One column can be selected for every row.
                                        </FormHelperText>}
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>                    
            }
		</div>
	)
}
