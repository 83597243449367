import React from 'react'
import FormSkeleton from '../Skeleton/FormSkeleton'
import CheckboxField from './Fields/Checkbox'
import CheckboxGrid from './Fields/CheckboxGrid'
import RadioField from './Fields/Radio'
import RadioGrid from './Fields/RadioGrid'
import SelectField from './Fields/Select'
import SliderField from './Fields/SliderField'
import TextInputField from './Fields/TextField'

export default function OptionsSection({ question, qindex, isLoading }) {
	// const selectInput = (que, option) => {
	// 	const k = answers.findIndex((ele) => (ele.question === que))
	// 	answers[k].answers = option
	// 	setAnswers(answers)
	// }
	return (
		<>
			{question.questionType === "TEXT" &&
                <TextInputField 
                    question={question}
                    qindex={qindex}
                />
            }
			{question.questionType === "DROPDOWN" &&
				<SelectField 
					question={question}
                    qindex={qindex}
				/>
			}
			{question.questionType === "RADIO" &&
				<RadioField 
					question={question}
                    qindex={qindex}
				/>
			}
			{question.questionType === "CHECKBOX" &&
				<CheckboxField 
                    question={question}
                    qindex={qindex}
                />
			}
			{question.questionType === "CHECKBOX_GRID" &&
				<CheckboxGrid 
                    question={question}
                    qindex={qindex}
                />
			}
			{question.questionType === "RADIO_GRID" &&
				<RadioGrid 
                    question={question}
                    qindex={qindex}
                />
			}
			{question.questionType === "SCALE_QUESTION" &&
				<SliderField 
                    question={question}
                    qindex={qindex}
                />
			}
            {isLoading && <FormSkeleton />}
		</>

	)
}
