import { useState } from "react";
import UserFormContext from "./UserFormContext";

const UserFormStateProvider = (props) => {   
    const [fromState, setFromState] = useState({
    });
    
    // const [ answers, setAnswers ] = useState({
    //     response: {}
    // })
    const [ answers, setAnswers ] = useState([{
        questionText: 'Question',
        questionType: "RADIO",
        data: [],
        
    }])
    
    return (
        <UserFormContext.Provider value={{answers, setAnswers, fromState, setFromState}}>
            {props.children}
        </UserFormContext.Provider>
    )
}
export default UserFormStateProvider