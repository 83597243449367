import React from 'react'
import TextField from '../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Stack } from '@mui/material';
import TextAreaField from '../Common/Fields/TextAreaField';
import DatePickerField from '../Common/Fields/DatePickerField';
import { stripLocationGroups, useLocationGroups } from '../../hooks/location';
import Select from 'react-select'
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Typography } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { stripCouponsCategory, useCouponsCategoryList } from '../../hooks/coupon';
import { getCurrentDate } from '../../utils';
import dayjs from 'dayjs';


export default function CouponAddFrom({ isLoading, onSubmit }) {
    const { locationGroupsData, locationGroupsIsLoading } = useLocationGroups();
    const { couponCategoryData, couponCategoryIsLoading } = useCouponsCategoryList();
    const SUPPORTED_FORMATS = ['image/png',  'image/jpeg'];
    // console.log(couponCategoryData.map(stripCouponsCategory))
    const validate = Yup.object({
        title: Yup.string().required(),
        description: Yup.string().required(),
        redeem_point: Yup.string().required(),
        redeem_limit: Yup.string().required(),
        expiry_date: Yup.string().required('Date is required.').nullable(),
        img: Yup.mixed()
            .required("Need a image file!")
            .test(
                'fileType',
                "Unsupported File Format! (allowed formats: png, jpg).",
                (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
            )
            .test(
                "fileSize",
                "File size is too big :( allowed size is 2MB.",
                // (value) => console.log(value.size)
                (value) => value && value.size <= 2154227
            ),
    }).shape({
        coupon_code: Yup.string().ensure().when('official_link', {
            is: '',
            then: Yup.string().required('Coupon or Link is required.')
        }),
        official_link: Yup.string().ensure().when('coupon_code', {
            is: '',
            then: Yup.string().required('Coupon or Link is required.')
        }),
    }, [['coupon_code', 'official_link']])
    return (
        <Formik
            initialValues={{
                title: '',
                description: '',
                redeem_point: '',
                redeem_limit: '',
                coupon_code: '',
                official_link: '',
                expiry_date: null,
                img: null,
                fileName: '',
                category: [],
                location_group: null,
                status: true,
            }}
            validationSchema={validate}
            onSubmit={onSubmit}
        >
            {formik => (
                <Form>
                    {/* {console.log(formik)} */}
                    <TextField label='Title' name='title' placeholder='Title' sx={{ mb: 3 }} />
                    <TextAreaField label='Description' name='description' placeholder='Description'  />
                    <FormHelperText error={true} >{formik.errors.description}</FormHelperText>
                    <div className='row mt-2'>
                        <div className='col-md-6 col-12'>
                            <TextField label='Coupon Code' name='coupon_code' placeholder='Code' sx={{ mb: 3 }} />
                        </div>
                        <div className='col-md-6 col-12'>
                            <TextField label='Link' name='official_link' placeholder='Link' sx={{ mb: 3 }} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-3 col-6'>
                            <TextField label='Redeem Pt.' type='number' name='redeem_point' placeholder='100' sx={{ mb: 3 }} />
                        </div>
                        <div className='col-md-3 col-6'>
                            <TextField label='Max Redeem' name='redeem_limit' placeholder='50' sx={{ mb: 3 }} />
                        </div>
                        <div className='col-md-6 col-12'>
                            <DatePickerField label="Expiry Date" openTo="year" disableHighlightToday={false} name="expiry_date" sx={{ mb: 3 }} minDate={dayjs(getCurrentDate('-'))} />
                        </div>
                    </div>
                    {/* <SelectField label="Zone Group" name="location_group" sx={{ mb: 3 }} /> */}
                    <Stack sx={{ mt: 3 }} spacing={1}>
                        <Typography variant="h9" color="#949494">
                            Select Location Group
                        </Typography>
                        {locationGroupsData && !locationGroupsIsLoading && <Select
                            options={locationGroupsData.map(stripLocationGroups)}
                            // onChange={(e) => console.log(e.value)}
                            onChange={(e) => formik.setFieldValue('location_group', e.value)}
                        />}

                        <Typography variant="h9" color="#949494" sx={{ mt: 3 }}>
                            Select Category
                        </Typography>
                        {couponCategoryData && !couponCategoryIsLoading && <Select
                            options={couponCategoryData.map(stripCouponsCategory)}
                            // onChange={(e) => console.log(e)}
                            isMulti
                            onChange={(e) => formik.setFieldValue('category', e)}
                        />}
                    </Stack>

                    <Stack sx={{ mt: 3 }} spacing={2}>
                        <Typography variant="subtitle2" color="#949494">
                            Image:
                        </Typography>
                        <Button variant="outlined" component="label">
                            {formik.values.fileName ? formik.values.fileName : 'Select image'}
                            <input
                                hidden
                                type="file"
                                onBlur={formik.handleBlur}
                                name="img"
                                accept="image/*"
                                onChange={(event) => {
                                    let reader = new FileReader();
                                    let file = event.target.files[0];
                                    if (file) {
                                        reader.readAsDataURL(file);
                                        reader.onloadend = () => formik.setFieldValue("fileName", file.name);
                                        formik.setFieldValue('img', file);
                                    }
                                    // let myFiles = Array.from(files);
                                    // formik.setFieldValue("img", file);
                                }}
                            />

                        </Button>
                        {/* <ErrorMessage name="img" /> */}
                        <FormHelperText error={true} >{formik.errors.img}</FormHelperText>
                    </Stack>

                    {/* <RadioField 
                    options={['Yes', 'No']}
                    name='status'
                /> */}
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        type="submit"
                        disableElevation
                        sx={{mt:2}}
                    >
                        Add
                    </LoadingButton>

                    {/* <Stack sx={{ width: '100%', mt: 3 }}>
                    {response.status === 401 && <Alert severity="error">{response.detail} </Alert>}
                    {response.status === 200 && <Alert severity="success"> Login Success!</Alert>}
                </Stack> */}
                </Form>
            )}

        </Formik>
    )
}
