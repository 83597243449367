
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { BrowserRouter } from 'react-router-dom'
import FormStateProvider from './context/form';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import UserFormStateProvider from './context/user';
import AppRoutes from './routes/AppRoutes';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';

const queryClient = new QueryClient();
Modal.setAppElement('#root');

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <FormStateProvider>
                <UserFormStateProvider>
                    <BrowserRouter>
                        <AppRoutes />
                    </BrowserRouter>
                </UserFormStateProvider>
            </FormStateProvider>
            
            <ToastContainer
                position="top-right"
                autoClose={4000}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
        </QueryClientProvider>
    );
}

export default App;
