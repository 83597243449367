/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import FormContext from '../../context/form/FormContext'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';
import Switch from '@mui/material/Switch';
import { useUpdateFormSetting } from '../../hooks/form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton';
import moment from 'moment';
import { useLocationGroups, stripLocationGroups, useGetLocGroupDetail } from '../../hooks/location';
// import { Checkbox, FormControlLabel, Stack, TextareaAutosize } from '@mui/material';
import Select from 'react-select'
// import DatePicker from '../Common/Fields/DatePickerField';
import DateField from '../UserForm/Fields/DateField';
// import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import dayjs from 'dayjs';

export default function FormSettings() {
    const { id } = useParams();
    const [locationGroup, setLocationGroup] = useState({})
    // const [expiryDate, setExpiryDate] = useState(null)
    const { handleUpdateFormSetting, updateFormSettingIsLoading } = useUpdateFormSetting();
    const { locationGroupsData, locationGroupsIsLoading } = useLocationGroups();
    const { fromInfo, setFormInfo } = useContext(FormContext);
    const { locGroupData, locGroupIsLoading } = useGetLocGroupDetail(fromInfo.location_group);
    // console.log(fromInfo)
    const handleSaveSettings = async () => {
        const res = await handleUpdateFormSetting(
            id,
            fromInfo.point,
            fromInfo.silver_point,
            fromInfo.gold_point,
            fromInfo.platinum_point,
            
            fromInfo.time_limit,
            fromInfo.editable,
            fromInfo.publish_date,
            fromInfo.expiry_date,
            fromInfo.location_group,
            fromInfo.status
        )
        res ? toast.info('Saved Settings.') : toast.error('Saving failed.')
    }

    useEffect(() => {
        locGroupData && !locGroupIsLoading && setLocationGroup({ label: locGroupData.name, value: locGroupData.id })
    }, [!locGroupIsLoading])

    return (
        <div className="question-form">
            <br />
            <div className="section">

                <div className="question-title-section">
                    <div className="question-form-top">
                        <Typography variant="h6" component="span" gutterBottom>{fromInfo.title} </Typography>
                        <Typography variant="h6" component="span" sx={{ color: fromInfo.status ? 'red' : 'grey'  }}>{fromInfo.status ?  '[Live]' :'[Draft]'} </Typography>
                        <Divider />
                        <Typography variant="button" gutterBottom>Primary Settings</Typography>
                        <Typography variant='body2' component="p">Created at: {moment(fromInfo.created_at).calendar()}</Typography>
                        <Typography variant='body2' component="p">Last updated: {moment(fromInfo.updated_at).calendar()}</Typography>
                        <Divider />
                        <Box sx={{ flexGrow: 1, marginTop: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography>Bronze Point:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <input type='number'
                                        className='text_input'
                                        style={{ width: '23%', borderBottom: '1px solid litegrey' }}
                                        value={fromInfo.point}
                                        placeholder='Strokes'
                                        onChange={(e) => setFormInfo({ ...fromInfo, point: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>Silver Point:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <input type='number'
                                        className='text_input'
                                        style={{ width: '23%', borderBottom: '1px solid litegrey' }}
                                        value={fromInfo.silver_point}
                                        placeholder='Strokes'
                                        onChange={(e) => setFormInfo({ ...fromInfo, silver_point: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>Gold Point:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <input type='number'
                                        className='text_input'
                                        style={{ width: '23%', borderBottom: '1px solid litegrey' }}
                                        value={fromInfo.gold_point}
                                        placeholder='Strokes'
                                        onChange={(e) => setFormInfo({ ...fromInfo, gold_point: e.target.value })}
                                    />
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <Typography>Platinum Point:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <input type='number'
                                        className='text_input'
                                        style={{ width: '23%', borderBottom: '1px solid litegrey' }}
                                        value={fromInfo.platinum_point}
                                        placeholder='Strokes'
                                        onChange={(e) => setFormInfo({ ...fromInfo, platinum_point: e.target.value })}
                                    />
                                </Grid> */}
                                <Grid item xs={6}>
                                    <Typography>Time Limit <code>{fromInfo.time_limit ? '(Enabled)' : '(Disabled)'}</code>:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <input type='number'
                                        placeholder='minutes'
                                        className='text_input'
                                        style={{ width: '23%', borderBottom: '1px solid litegrey' }}
                                        value={fromInfo.time_limit ?? ''}
                                        onChange={(e) => setFormInfo({ ...fromInfo, time_limit: e.target.value })}
                                    />
                                    <code> (in minutes)</code>
                                    {fromInfo.time_limit && <code><small> [Remove the time to `disable`.]</small></code>}
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        Select Location Group:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {locationGroupsData && !locationGroupsIsLoading && <Select
                                        options={locationGroupsData.map(stripLocationGroups)}
                                        onChange={(e) => {
                                            setLocationGroup(e)
                                            setFormInfo({ ...fromInfo, location_group: e.value })
                                        }}
                                        value={locationGroup}
                                    />}
                                </Grid>
                                

                                <Grid item xs={6}>
                                    <Typography>Editable:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Switch
                                        checked={fromInfo.editable}
                                        onChange={() => setFormInfo({ ...fromInfo, editable: !fromInfo.editable })}
                                        name="editable"
                                        color="primary"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>Publish:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Switch
                                        checked={fromInfo.status}
                                        onChange={() => setFormInfo({ ...fromInfo, status: !fromInfo.status })}
                                        name="status"
                                        color="primary"
                                    />
                                </Grid>
                                {!fromInfo.status && <>
                                    <Grid item xs={6}>
                                        <Typography>
                                            Auto Publish Date: <code>{fromInfo.publish_date ? '(Enabled)' : '(Disabled)'}</code>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DateField
                                            label={'Select Date'}
                                            value={fromInfo.publish_date}
                                            onChange={(e) => setFormInfo({ ...fromInfo, publish_date: e })}
                                        />
                                        {fromInfo.publish_date && <code><small>Remove the date to `disable`.</small></code>}
                                    </Grid>
                                </>}
                                {fromInfo.status && <>
                                    <Grid item xs={6}>
                                        <Typography>
                                            Auto Expiry Date: <code>{fromInfo.expiry_date ? '(Enabled)' : '(Disabled)'}</code>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DateField
                                            label={'Select Date'}
                                            value={fromInfo.expiry_date}
                                            onChange={(e) => setFormInfo({ ...fromInfo, expiry_date: e })}                                      
                                        />
                                        {fromInfo.expiry_date && <code><small>Remove the date to `disable`.</small></code>}
                                    </Grid>
                                </>}
                            </Grid>
                        </Box>
                    </div>
                </div>
                <div className="add_footer">
                    <div className="add_question_bottom_left">
                        <LoadingButton
                            variant="outlined"
                            loading={updateFormSettingIsLoading}
                            startIcon={<SettingsIcon />}
                            onClick={handleSaveSettings}>Save Settings </LoadingButton>

                    </div>
                </div>
            </div>
        </div>
    )
}
