import React, { useContext } from 'react'
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import UserFormContext from '../../../context/user/UserFormContext';


export default function TextInputField({ question, qindex }) {
    const { answers, setAnswers } = useContext(UserFormContext);
    const handleChange = (e, qid, oid) => {
        let answer = [...answers];
        answer[qid].data = {...answer[qid].data, ['option' + oid]: e.target.value}       
        setAnswers(answer);
        // console.log('OID -> ', oid)
    };
    return (
        <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 1 },
                width: 500,
                maxWidth: '100%',
            }}
            autoComplete="off"
        >
            {question.options.map((option, index) => (
                <TextField 
                    fullWidth 
                    key={index} 
                    label={option.optionText} 
                    variant="standard" 
                    onChange={(e) => handleChange(e, qindex, index)}
                />
            ))}
        </Box>
    )
}
