import { Button } from '@mui/material'
import React from 'react'
import { Link } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import { useDeleteForm } from '../../hooks/form';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function FormFooter({ formId, handleSurveySubmit, updateSubmissionIsLoading }) {
    let navigate = useNavigate();
    const { handleDeleteForm } = useDeleteForm();

    const handleDeleteFormAction = async (form_id) => {
        const toast_process = toast.loading("Deleting...")
        const res = await handleDeleteForm(form_id)

        res.status === 200 ? toast.update(toast_process, { render: `Survey has been deleted.`, type: "success", isLoading: false, autoClose: 4000, }) : toast.update(toast_process, { render: `Referenced through restricted.`, type: "error", isLoading: false, autoClose: 4000, });
        
        res === 200 && navigate(`/`); 
    }
	return (
		<>
			<div className="user_form_submit">
				
                <LoadingButton 
                    variant="contained" 
                    color="success"
                    loading={updateSubmissionIsLoading} 
                    onClick={handleSurveySubmit}> Submit Survey</LoadingButton>
				
				<Link to={`/form/${formId}`}>
					<Button variant="contained" color="primary" style={{ marginLeft: 10 }}>Edit Form</Button>
				</Link>
                <Button variant="contained" onClick={() => handleDeleteFormAction(formId)} color="error" style={{ marginLeft: 10 }}>Delete</Button>
			</div>
			<div className="user_footer" style={{marginTop: 50}}>
				Echo Strokes Surveys
			</div>

		</>
	)
}
