import { Button, Container, Grid, Stack } from '@mui/material';
import React from 'react';
import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
import LocationGroupCreateModal from '../../components/Modal/LocationGroupCreateModal'
import { useDeleteLocationGroup, useLocationGroups } from '../../hooks/location';
import DataTableBase from '../../components/Common/DataTableBase';
import Loader from '../../components/Loader';
import { Link } from 'react-router-dom';
import moment from 'moment';
import FilterComponent from '../../components/Common/FilterComponent';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

const paginationComponentOptions = {
    rowsPerPageText: 'Show groups per page',
    rangeSeparatorText: 'of',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Groups',
};

export default function LocationGroupsPage() {
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const { locationGroupsData, locationGroupsIsLoading } = useLocationGroups();
    const { handleDeleteGroup, isGroupDeleting } = useDeleteLocationGroup();
    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
            width: "50px"
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: "250px",
        },
        {
            name: 'Description',
            selector: row => row.description === '' ? '-' : row.description,
            sortable: false,
        },
        {
            name: 'Status',
            selector: row => row.status ? 'Active' : 'Deactivated',
            sortable: true,
            width: "70px"
        },
        {
            name: 'Logic Count',
            selector: row => row.logic_count,
            sortable: true,
            hide: "md",
            width: "120px"
        },
        {
            name: 'Linked Surveys',
            selector: row => row.used_survey_count,
            sortable: true,
            hide: "md",
            width: "120px"
        },
        {
            name: 'Created at',
            width: "150px",
            selector: row => moment(row.created_at).format('DD-MM-YYYY hh:mm')
        },
        {
            name: 'Updated at',
            width: "150px",
            selector: row => moment(row.updated_at).format('DD-MM-YYYY hh:mm')
        },
        {
            name: "Buttons",
            button: true,
            width: "200px",
            cell: (row) => (<>
                <Link to={`/location/group/logic/${row.id}`} className='btn btn-sm btn-outline-dark mx-2'>
                    View
                </Link>
                <button className='btn btn-sm btn-outline-danger' onClick={() => handleDeleteLocationGroup(row.id)}>
                    delete
                </button>
            </>)
        }
    ];

    // Table Header
    const subHeaderComponent = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    // Loader
    if (locationGroupsIsLoading) {
        return <Loader />
    }

    // Filter data
    const filteredItems = locationGroupsData.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );

    // Delete Action
    const handleDeleteLocationGroup = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const res = await handleDeleteGroup(id)
                        if (res.success) {
                            toast.success('Deleted successfully.')
                        } else {
                            toast.error(res.detail)
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => toast.info('Action cancelled.')
                }
            ]
        });
    };

    return (
        <Container maxWidth="xxl">

            <PageBreadcrumbs
                title='Location Groups'
            // path={[{name: 'test',url:'/'}, {name: 'test2',url:'/'}]}
            />
            <Stack direction="row" spacing={2} sx={{ mb: 5 }}>
                <Button variant="contained" data-bs-toggle="modal" data-bs-target="#createLocGroup">Create new group</Button>
                <LocationGroupCreateModal />
            </Stack>

            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                {
                    locationGroupsData && !locationGroupsIsLoading && <DataTableBase
                        // title="Groups List"
                        columns={columns}
                        data={filteredItems}
                        direction="auto"
                        fixedHeaderScrollHeight="300px"
                        pagination
                        responsive
                        subHeaderAlign="right"
                        subHeaderWrap
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                        progressPending={locationGroupsIsLoading ?? isGroupDeleting}
                        subHeader
                        striped
                        subHeaderComponent={subHeaderComponent}
                        paginationComponentOptions={paginationComponentOptions}
                        highlightOnHover
                        pointerOnHover

                    />
                }
            </Grid>
        </Container>
    )
}
