export const checkHasValue = (arr, value) => arr?.indexOf(value) > - 1


export function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
}

export function getCurrentDate(separator = '') {
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
}

export const truncate = (str, max, suffix=' ...') => str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;

export const smartTrim = (str, length, delim=' ', appendix=' ...') => {
    if (str.length <= length) return str;

    var trimmedStr = str.substr(0, length+delim.length);
    var lastDelimIndex = trimmedStr.lastIndexOf(delim);

    if (lastDelimIndex >= 0) trimmedStr = trimmedStr.substr(0, lastDelimIndex);

    if (trimmedStr) trimmedStr += appendix;
    return trimmedStr;
}


export function capitalize(str) {
    try {
      const lower = str.toLowerCase();
      return str.charAt(0).toUpperCase() + lower.slice(1);
    } catch (error) {
      return str;
    }
}