import {  useRef } from "react";
import { toast } from 'react-toastify';
import { useLocationGroupLogic } from "../../hooks/location";
import GroupLogicForm from "../Form/GroupLogicForm";


export default function LocationGroupLogicModal({ group_id }) {
    const closeGroupLogicModal = useRef();
    const { handleGroupLogic, groupLogicIsLoading } = useLocationGroupLogic()
    const handleSubmit = async (values, { resetForm }) => {        
        const res = await handleGroupLogic({...values, group: group_id}, 'POST');
        // console.log('res-> ', res)
        if (res.success){
            toast.success('New logic created.');
            closeGroupLogicModal.current.click();
            resetForm({values: ''});
        } else {
            toast.error(res.detail)
        }
    }
    return (
        <div>
            <div className="modal fade" id="addGroupLogic" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="groupLogicLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="groupLogicLabel">Add Location Group Logic</h5>

                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" ref={closeGroupLogicModal}></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <GroupLogicForm
                                    isLoading={groupLogicIsLoading}
                                    onSubmit={handleSubmit}
                                />
                                {/* <ProfileCreateForm 
                                    UpdateIsLoading={UpdateIsLoading}
                                    errors={errors}
                                    handleSubmit={handleSubmit}
                                />                                 */}
                            </div>
                        </div>
                        {/* <div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={closeSwitchProfileRef}>Close</button>
							<button type="button" className="btn btn-primary" onClick={handleSwitchUserSubmit}>Switch Account</button>
						</div> */}

                        {/* <div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
						</div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
