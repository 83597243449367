/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import './UserForm.css'
import { Typography } from '@mui/material';
import OptionsSection from './OptionsSection';
import FormFooter from './FormFooter';
import FormTitleDescSkeleton from '../Skeleton/FormTitleDescSkeleton';
import UserFormContext from '../../context/user/UserFormContext';
import { useSubmissionDetail, useUpdateSubmission } from '../../hooks/user_form';
import { toast } from 'react-toastify';


export default function UserQuestionForm({ formId, fromInfo, questions, isLoading }) {
    const { answers, setAnswers } = useContext(UserFormContext);
    const {data, status } = useSubmissionDetail(formId);
    const { handleUpdateSubmission, updateSubmissionIsLoading } = useUpdateSubmission();
    
    useEffect(() => {
        if( data ){
            setAnswers(data.form_data)
        }
    }, [status === 'success' && !status === 'loading'])
    
    const handleSurveySubmit = async () => {
        const res = await handleUpdateSubmission(formId, answers)
        res ? toast.info('Submited Changes.') : toast.error('Submiting failed.')
    }
    return (
        <div className="submit">
            <div className="user_form">
                <div className="user_form_section">
                    <div className="user_title_section">
                        {isLoading ? 
                            <FormTitleDescSkeleton />
                        :
                            <>
                                <Typography style={{ fontSize: "26px" }}>{fromInfo.title}</Typography>
                                {fromInfo.description && <Typography style={{ fontSize: "15px" }}>{fromInfo.description}</Typography> }
                            </>
                        }
                    </div>
                    {questions.map((question, qindex) => (
                        <div className="user_form_questions" key={qindex}>
                            {/* {console.log('Question ID: ', qindex, '- Question Type: ', question.questionType, '- Question:', question)} */}
                            
                            <Typography style={{fontWeight: "400", letterSpacing: '.1px', lineHeight: '24px', paddingBottom: "8px"}}>
                                {qindex + 1}. {question.questionText} {question.required  && <span style={{ color: "red", fontWeight: "bold"}}>*</span>}
                            </Typography> 

                            {question.questionDesc && <Typography style={{ fontSize: "13px", color:'#464343', fontWeight: "400", letterSpacing: '.1px', lineHeight: '20px', paddingBottom: "8px", marginTop:2 }} >{question.questionDesc}</Typography>}
                                <OptionsSection
                                    question={question} 
                                    qindex={qindex}
                                    isLoading={isLoading}
                                />
                        </div>
                    ))}
                    <FormFooter 
                        formId={formId}
                        handleSurveySubmit={handleSurveySubmit}
                        updateSubmissionIsLoading={updateSubmissionIsLoading}
                    />
                </div>
            </div>
        </div>

    )
}
