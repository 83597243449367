/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import UserQuestionForm from '../../components/UserForm/QuestionForm';
import FormContext from '../../context/form/FormContext';
import UserFormContext from '../../context/user/UserFormContext';
import { getFormDetail } from '../../libs/form';


export default function FormPreviewPage() {
	const { id } = useParams();
	const [ isLoading, setIsLoading ] = useState(true)
	const { questions, setQuestions, fromInfo, setFormInfo } = useContext(FormContext);
	const { answers, setAnswers, fromState, setFromState } = useContext(UserFormContext);
    
	const fetchFormDetail = async () => {
        try {
            const data = await getFormDetail(id);
            if (data){
                setFormInfo(data)
                Object.keys(data.form_data).length > 0 && setQuestions(data.form_data)

                // Creating Instance of Answers State
                let ansArr = []
                data.form_data.map((v, qid) => 
                    ansArr.push({questionText: v.questionText, questionType: v.questionType, data: []})
                )
                setAnswers(ansArr)

                setIsLoading(false)
            }

        } catch (err) {
            console.log('err -> ',err)
        }
    }
    useEffect(() => {
        fetchFormDetail();
    }, []) 
	return (
		<div>
			<UserQuestionForm 
				formId={id}
				fromInfo={fromInfo}
				questions={questions}
				isLoading={isLoading}
			/>
		</div>
	)
}
