import { useQuery } from "@tanstack/react-query";
import { API_HOST_URL } from "../config";
import { endpoints } from "../config/endpoints";
import { fetchJson } from "../libs/api";
import { getTokens } from "../utils/shortcuts";


const SUBMISSIONS_QUERY_KEY = 'user_submissions';

//  Get user's Transactions
export function useUserSubmissions(user_id, enabled) {
    const { accessToken } = getTokens();
    
    const { isLoading, isError, data, status } = useQuery(
        [SUBMISSIONS_QUERY_KEY, enabled], async () => {
            try {
                // console.log('CALLED DIPENDED QUERY',user_id )
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.users.submissions}/${user_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                // console.log('hook data ->', data)
                const data = await res.json()
                return data
            } catch (err) {
                return undefined;
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        enabled: !!enabled,
        retry: 3,
        refetchOnMount: true, // ms
    })
    
    return { submissionsData: data, submissionsIsLoading: isLoading, isError, status }
}