import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';

import TextField from '@mui/material/TextField';

export default function CompactQuestion({ questions, question, i }) {
    const { onePerColumn, transpose } = question.questionGridItem;
    return (
        <div className="saved_question">

            <Typography>
                {i + 1}. {questions[i].questionText}
            </Typography>


            <Stack direction="row">
                <Box sx={{ p: 0.5, mt: 2, border: '1px dashed grey' }} align="center">
                    <Typography variant='p'>
                        {questions[i].questionType}
                    </Typography>
                </Box>
                {transpose && <Box sx={{ ml:1, p: 0.5, mt: 2, border: '1px dashed grey' }} align="center">
                    <Typography variant='p'>
                        Transposed
                    </Typography>
                </Box>}
                {onePerColumn && <Box sx={{ ml:1, p: 0.5, mt: 2, border: '1px dashed grey' }} align="center">
                    <Typography variant='p'>
                        One per column
                    </Typography>
                </Box>}
            </Stack>

            {(question.questionType === 'TEXT' || question.questionType === 'RADIO' || question.questionType === 'CHECKBOX' || question.questionType === 'DROPDOWN') &&
                question.options.map((option, j) => (
                    <div key={j}>
                        <div style={{ display: 'flex' }}>
                            <TextField label={option.optionText} disabled variant="standard" />
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
