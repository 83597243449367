import React from 'react'
import './Main.css';
// import { IconButton } from '@mui/material';
// import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import MainCard from './Card';
import { useDeleteForm, useFormsList } from '../../hooks/form';
import { toast } from 'react-toastify';
import Loader from '../Loader';

export default function Main() {
    const { formData, formIsLoading, formIsError } = useFormsList();
    const { handleDeleteForm } = useDeleteForm();
    // console.log(formData)
    const handleDeleteFormAction = async (form_id, title) => {
        const toast_process = toast.loading("Deleting...")
        const res = await handleDeleteForm(form_id)
        res.status === 200 ? toast.update(toast_process, { render: `${title} has been deleted..`, type: "success", isLoading: false, autoClose: 4000, }) : toast.update(toast_process, { render:`${title} referenced through restricted.`, type: "error", isLoading: false, autoClose: 4000, }) 
    }

	return (
		<div className="main-body">
			<div className="body-top">
				<div className="body-top-left" style={{fontSize: '16px', fontWeight: '500'}}>
					Echo Strokes Surveys
				</div>
				
				<div className="body-top-right">
					{/* <div className="body-top-right-center" style={{fontSize: '14px', marginRight: '125px'}}>
						Owned by anyone 
						<ArrowDropDownIcon />
					</div> */}
					{/* <IconButton>
						<FolderOpenIcon style={{fontSize: '14px', color: 'black'}} />
					</IconButton> */}

				</div>
			</div>
			<div className="main-docs">
				<div className="main-docs-card">
					{/* <div className="main-docs-card-content">
						<h5>created 3 days ago</h5>
						<div className="doc-content" style={{fontSize: '12px', color: 'grey'}}>
							<div className="doc-content_left">
								<StorageIcon style={{color: 'white', backgroundColor: '#6E2594', padding: '3px', marginRight: '3px', borderRadius: '2px'}} />
							</div>
							<MoreVertIcon style={{fontSize: '16px', color: 'grey'}} />
						</div>
					</div> */}

				</div>
                {formIsLoading && <Loader />}
                {formIsError ? 
                    <h3>Error communicating with server.</h3>
                :
                    formData && !formIsLoading &&
                        formData.map((data, i) => (
                            <MainCard 
                                key={i}
                                data={data}
                                handleDeleteForm={handleDeleteFormAction}
                            />
                        ))                
                }
			</div>
		</div>
	)
}
