import { useState } from "react";
import FormContext from "./FormContext";

const FormStateProvider = (props) => {   
    const [fromInfo, setFormInfo] = useState({
        title: '',
        description: '',
        
        point: 10,
        silver_point: 10,
        gold_point: 10,
        platinum_point: 10,

        time_limit: 10,
        editable: false,
        publish_date: null,
        expiry_date: null,
        location_group: null,
        status: true
    });
    
    // Change also from AdminForm/FormSidebar.js 
    const [ questions, setQuestions ] = useState([{
        questionText: 'Question',
        questionDesc: '',
        showDescription: false,
        questionType: "RADIO",
        options: [],
        questionGridItem: {
            rows: [],
            columns:{
                type:"RADIO",
                options:[]
            },
            scale: {}
        },
        open: true,
        required: false,
    }])
    
    return (
        <FormContext.Provider value={{questions, setQuestions, fromInfo, setFormInfo}}>
            {props.children}
        </FormContext.Provider>
    )
}
export default FormStateProvider