import React from 'react'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import { IconButton } from '@mui/material';
export default function ScaleOption({ question, i, questions, setQuestions }) {
	const handleChange = (key, value) => {
		let optionOfQuestion = [...questions];
		const options = optionOfQuestion[i].questionGridItem.scale;
		optionOfQuestion[i].questionGridItem.scale = { ...options, [key]: value }
		setQuestions(optionOfQuestion)
		// console.log(questions)
		// console.log(optionOfQuestion[i].questionGridItem.scale)
	}
	return (
		<div className='add_question_body'>
			<Box
				component="form"
				sx={{ gap: 1, mb: 5, mt: 2 }}
				autoComplete="off"
			>
				<TextField
					sx={{ width: 120, mx: 2 }}
					onChange={(e) => handleChange('min', e.target.value)}
					type="number"
					label="Min"
					value={question.questionGridItem.scale?.min ?? ''}
					variant="standard"
					error={!question.questionGridItem.scale?.min && true}
				/>
				<TextField
					sx={{ width: 120, mx: 2 }}
					onChange={(e) => handleChange('max', e.target.value)}
					type="number"
					label="Max"
					value={question.questionGridItem.scale?.max ?? ''}
					variant="standard"
					error={!question.questionGridItem.scale?.max && true}
				/>
				<TextField
					sx={{ width: 120, mx: 2 }}
					onChange={(e) => handleChange('interval', e.target.value)}
					type="number"
					label="Interval"
					value={question.questionGridItem.scale?.interval ?? ''}
					variant="standard"
					error={!question.questionGridItem.scale?.interval && true}
				/>
				{/* <IconButton aria-label="add" size="small">
					<AddCircleIcon fontSize="inherit" />
				</IconButton> */}
			</Box>
		</div>
	)
}
