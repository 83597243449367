import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { API_HOST_URL } from '../config';
import { endpoints } from '../config/endpoints';
import { fetchJson } from '../libs/api';
import { getTokens } from '../utils/shortcuts';


const FORMS_QUERY_KEY = 'preview_forms'

// Fetch All Submission from the server
export function useSubmissionList() {
    const { accessToken } = getTokens();
    const { isLoading, isError, data } = useQuery(
        [FORMS_QUERY_KEY], async () => {
		try {
            return await fetchJson(`${API_HOST_URL}/${endpoints.survey.submission_list}`, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            })
		} catch (err) {
			return undefined;
		}
	}, {
        // enabled: false,
		cacheTime: Infinity,
		staleTime: 360_0000, // ms 3600 Sec
	})
	return { submissionData: data, submissionIsLoading: isLoading, submissionIsError: isError }
}


// // Fetch Single Submission from the server
export function useSubmissionDetail(form_id) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [FORMS_QUERY_KEY], async () => {
		try {
            const data = await fetchJson(`${API_HOST_URL}/${endpoints.survey.submission_list}/${form_id}`, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            })
            // console.log('hook data ->', data)
            return data 
		} catch (err) {
			return undefined;
		}
	}, {
		cacheTime: 0,
		staleTime: 1,
        // enabled: !!questions,
        // retry: 0,
        refetchOnMount: true, // ms
	})
	return { data, dataIsLoading:isLoading, isError, status }
}



// Update Form data to the server
export function useUpdateSubmission() {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
	const mutation = useMutation(({ form_id, form_data }) => fetchJson(`${API_HOST_URL}/${endpoints.survey.submission_list}/${form_id}`, {
		method: 'PATCH',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ form_data })
	}), {
        retry: 3,
    })
    return { 
        handleUpdateSubmission: async (form_id, form_data) => {            
            try {
                await mutation.mutateAsync({ form_id, form_data  });
                await queryClient.invalidateQueries([FORMS_QUERY_KEY])
                return true;
            } catch (err) {
                return false
            }            
        },
        updateSubmissionIsError: mutation.isError,
        updateSubmissionIsLoading: mutation.isLoading,
        updateSubmissionIsSuccess: mutation.isSuccess,
    }
}

