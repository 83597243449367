import { useRef } from "react";
import { toast } from 'react-toastify';
import { useCouponCategory } from "../../hooks/coupon";
import AddCouponCategoryForm from "../Form/AddCouponCategoryForm";


export default function AddCouponCategoryModal() {
    const closeCategoryGroupModal = useRef();
    const { handleCreateCategory, isCouponCategoryLoading } = useCouponCategory()
    const handleSubmit = async (values, { resetForm }) => {
        const { name, order, status } = values;
        const res = await handleCreateCategory({ name, order, status: status === 'Active' })
        if (res.success) {
            toast.success(res.detail);
            closeCategoryGroupModal.current.click();
            resetForm({ values: '' });
        } else {
            toast.error(res.detail)
        }
    }
    return (
        <div>
            <div className="modal fade" id="addCouponCategory" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="CouponCategoryLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="CouponCategoryLabel">Add Coupon Category</h5>

                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" ref={closeCategoryGroupModal}></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <AddCouponCategoryForm
                                    isLoading={isCouponCategoryLoading}
                                    onSubmit={handleSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
