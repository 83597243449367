import React, { useContext } from 'react'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import UserFormContext from '../../../context/user/UserFormContext';

export default function CheckboxField({ question, qindex }) {
    const { answers, setAnswers } = useContext(UserFormContext);
    const handleCheckboxChange = (value, oid) => {
        let answer = [...answers];
        answer[qindex].data = {...answer[qindex].data, ['option' + oid]: value}       
        setAnswers(answer);
    }
    return (
        
            <FormGroup>
                {question.options.map((option, index) => (
                    <FormControlLabel key={index} value={option.optionText} onChange={() => handleCheckboxChange(option.optionText, index)} control={<Checkbox />} label={option.optionText} />
                ))}
            </FormGroup>
    )
}
