import React from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getCurrentDate } from '../../../utils';
// import moment from "moment";


export default function DateField({label, value, onChange}) {
    // console.log('meta -> ', meta);
    return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    // onChange={(option) => helpers.setValue(moment(option).format('YYYY-MM-DD'))}
                    label={label}     
                    onChange={onChange}            
                    inputFormat="DD/MM/YYYY"
                    value={value}
                    name='test'
                    minDate={dayjs(getCurrentDate('-'))}
                    renderInput={(params) => <TextField variant='standard' name='test' {...params} fullWidth  autoComplete='off'  />
                }
                />
            </LocalizationProvider>
    )
}